import { ReactNode } from 'react';

import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { msalInstance } from './AuthHelpers';

const authRequest = {
    scopes: ['openid', 'profile'],
};

const ErrorComponent = () => {
    return <p>An Error Occurred</p>;
};

const LoadingComponent = () => {
    return <p>Authentication in progress...</p>;
};

const AuthWrapper = ({ children }: { children?: ReactNode | undefined }) => {
    const callbackId = msalInstance.addEventCallback((message: EventMessage) => {
        // Update UI or interact with EventMessage here
        if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
            msalInstance.loginRedirect();
        }
    });

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={LoadingComponent}
            >
                {children}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};

export default AuthWrapper;
