import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem, GridRowId, useGridApiContext } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

const EditLinkCellItem = ({ rowId, path }: { rowId: GridRowId, path: string }) => {
    const apiRef = useGridApiContext();
    let navigate = useNavigate();

    return apiRef.current.getRowMode(rowId) === 'view' ? (
        <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(path, { replace: true })}
            color="inherit"
        />
    ) : null;
};

export default EditLinkCellItem;
