import { CircularProgress } from '@mui/material';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import { styled, SxProps, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

export interface FilterResponse {
    id?: string | number;
    label?: string | null;
    searchText?: string | null;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };
    const dataProps = dataSet[0];
    const filterItem = dataSet[1];

    return (
        <Typography component="li" {...dataProps} noWrap style={inlineStyle}>
            {filterItem.label}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
    props,
    ref,
) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

export const VirtualizedFilterControlledDropdown = ({
    label,
    options,
    name,
    control,
    disabled,
    required,
    isLoading,
    freeSolo,
    onChange,
    sx = { flex: 1 },
}: {
    name: string;
    label: string;
    options: FilterResponse[];
    control: Control<any, any>;
    disabled?: boolean;
    required?: boolean;
    isLoading?: boolean;
    freeSolo?: boolean;
    onChange?: (data: any) => void;
    sx?: SxProps;
}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={{ required: required }}
            render={({ field, fieldState, formState: { errors } }) => {
                return (
                    <Autocomplete
                        {...field}
                        freeSolo={freeSolo}
                        autoSelect={freeSolo}
                        value={field.value || null}
                        disabled={disabled}
                        sx={sx}
                        disableListWrap
                        fullWidth
                        PopperComponent={StyledPopper}
                        ListboxComponent={ListboxComponent}
                        options={isLoading ? [{ id: -1, label: 'Loading...' }] : options}
                        isOptionEqualToValue={(opt1, opt2) => {
                            return opt2 === undefined || opt2 === "" || (opt1 as FilterResponse).id === (opt2 as FilterResponse).id;
                        }}
                        filterOptions={createFilterOptions({
                            matchFrom: 'any',
                            stringify: (option) => `${option.id} ${option.label} ${option.searchText}`
                        })}
                        getOptionLabel={(option) => (option as FilterResponse).label || ''}
                        renderOption={(props, option) => {
                            return [props, option] as any;
                        }}
                        renderInput={(params) => isLoading ? (<TextField {...params} label={<><CircularProgress size={20} /> {label}</>} />) : (
                            <TextField
                                {...params}
                                required={required}
                                label={label}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                            />
                        )}
                        onChange={(_, data) => {
                            if (typeof data === "string") {
                                field.onChange({ id: data, label: data });
                            } else {
                                field.onChange(data);
                            }
                            onChange && onChange(data);
                        }}
                    />
                );
            }}
        />
    );
};

export const VirtualizedFilterDropdown = ({
    label,
    options,
    disabled,
    onChange,
    value,
    isLoading,
}: {
    onChange: (filter: FilterResponse | null) => void;
    label: string;
    value: FilterResponse | null;
    options: FilterResponse[];
    disabled?: boolean;
    isLoading?: boolean;
}) => {
    return (
        <Autocomplete
            // {...field}
            disabled={disabled}
            sx={{ flex: 1 }}
            disableListWrap
            value={value}
            fullWidth
            filterOptions={createFilterOptions({
                matchFrom: 'any',
                stringify: (option) => `${option.id} ${option.label} ${option.searchText}`
            })}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={isLoading ? [{ id: -1, label: 'Loading...' }] : options}
            isOptionEqualToValue={(opt1, opt2) => {
                return opt2 === undefined || opt2 === "" || (opt1 as FilterResponse).id === (opt2 as FilterResponse).id;
            }}
            getOptionLabel={(option) => (option as FilterResponse).label || ''}
            renderOption={(props, option) => {
                return [props, option] as any;
            }}
            renderInput={(params) => {
                return isLoading ? (<TextField {...params} label={<><CircularProgress size={20} /> {label}</>} />) : (<TextField {...params} label={label} />)
            }}
            onChange={(_, data) => {
                onChange(data);
            }}
        />
    );
};
