import { Button, ButtonProps } from "@mui/material";
import { useEffect, useState } from 'react';

interface IProps extends ButtonProps {
    setFromDate?: (fromDate: string) => void;
    setToDate?: (toDate: string) => void;
    daysOffset?: number,
    daysTake?: number,
    initToThis?: boolean,
}

function XDayButton({ daysOffset = 0, daysTake = 0, initToThis = false, setFromDate, setToDate, children, ...rest }: IProps) {

    function targetDayInPast(daysOffset: number, daysTake: number) {
        let day = new Date();
        day.setDate(day.getDate() + daysOffset);
        let nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + daysOffset + daysTake);
        setFromDate && setFromDate(day.toISOString().split('T')[0]);
        setToDate && setToDate(nextDay.toISOString().split('T')[0]);
    }

    const [init, setInit] = useState<boolean>(initToThis);

    useEffect(() => {
        if (initToThis && init) {
            setInit(false);
            targetDayInPast(daysOffset, daysTake);
        }
    }, [initToThis]);

    return (
        <Button
            type="button"
            onClick={() => targetDayInPast(daysOffset, daysTake)}
            variant="outlined"
            color="primary"
            {...rest}
        >
            {children}
        </Button>)
};

export default XDayButton;