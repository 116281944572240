import { Stack } from "@mui/material";
import { GridPagination, GridToolbarColumnsButton } from "@mui/x-data-grid";
import CsvDownloadLink, { CsvDownloadLinkProps } from "./CsvDownloadLink";

type Props<T> = {
    csvProps?: CsvDownloadLinkProps<T>;
    includeColumns?: boolean;
    includePagination?: boolean;
};

function CustomDataGridToolbar<T extends { items?: any[] | undefined | null }>({ csvProps, includeColumns = true, includePagination = true }: Props<T>) {
    return (
        <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' justifyContent='flex-start'>
                {includeColumns && <GridToolbarColumnsButton />}
                {csvProps && <CsvDownloadLink {...csvProps} />}
            </Stack>
            <Stack direction='row' justifyContent='flex-end'>
                {includePagination && <GridPagination />}
            </Stack>
        </Stack>
    );

};

export default CustomDataGridToolbar;
