import BugReportIcon from '@mui/icons-material/BugReport';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Box, Button, ListItemButton, Modal } from "@mui/material";
import { useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { getActiveUser } from "../../AuthHelpers";

const FeedbackModal = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const copyAppDetails = () => {
        const details = {
            url: window.location.toString(),
            user: getActiveUser(),
            browserName,
            browserVersion
        };
        navigator.clipboard.writeText(JSON.stringify(details, null, 4));
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <ListItemButton onClick={handleOpen} sx={{ textDecoration: 'none' }}>
                <BugReportIcon />Submit Feedback
            </ListItemButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ol>
                        <li>Click the <Button onClick={copyAppDetails} color="secondary" variant="contained"><ContentPasteIcon />Get App Details</Button></li>
                        <li>Paste the result into the "App Details" question below</li>
                        <li>Answer any other questions</li>
                        <li>Click Submit</li>
                    </ol>
                    <iframe width="640px" height="480px" src="https://forms.office.com/r/kuhtCnLUp6?embed=true"> </iframe>
                    <Button onClick={handleClose}>Cancel</Button>
                </Box>
            </Modal>
        </>
    );
};

export default FeedbackModal;