import { Button, Paper, Stack, TextField } from "@mui/material";
import { DataGrid, GridColumns, GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import EditLinkCellItem from '../../components/EditLinkCellItem';
import CustomDataGridToolbar from "../../components/CustomDataGridToolbar";
import XDayButton from "../../components/XDayButton";
import { DEFAULT_PAGE_SIZE, MAX_32_BIT_INT } from "../../constants";
import { setExclusiveToDate } from "../../helpers/DateHelper";
import useDebounce from "../../hooks/useDebounce";
import { moneyManagerGetMoneyManagerHistory, useMoneyManagerGetMoneyManagerHistory } from "../../services/commissionapi";
import { OrderByDirection } from "../../services/model";

const MoneyManagerHistoryPage = () => {
    const [fromDate, setFromDate] = useState<string>(sessionStorage.fromDate ? JSON.parse(sessionStorage.fromDate) : new Date().toISOString().split('T')[0]);
    const [toDate, setToDate] = useState<string>(sessionStorage.toDate ? JSON.parse(sessionStorage.toDate) : new Date().toISOString().split('T')[0]);
    const debouncedFromDate = useDebounce(fromDate, 500);
    const debouncedToDate = useDebounce(toDate, 500);
    const [page, setPage] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(sessionStorage.sortModel ? JSON.parse(sessionStorage.sortModel) : [{ field: "dateTime", sort: "desc" }]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(sessionStorage.columnVisibilityModel ? JSON.parse(sessionStorage.columnVisibilityModel) : {});

    const navigate = useNavigate();

    const { data, isLoading, isFetching } = useMoneyManagerGetMoneyManagerHistory({
        fromDate: debouncedFromDate,
        toDate: setExclusiveToDate(debouncedToDate),
        page: page,
        perPage: perPage,
        orderBy: (sortModel && sortModel[0] && sortModel[0].field) || '',
        orderByDirection: sortModel && sortModel[0] && sortModel[0].sort === 'asc' ? OrderByDirection.Asc : OrderByDirection.Desc,
    }, { query: { enabled: debouncedFromDate !== undefined } });

    const setFromDateSession = (value: string) => {
        setFromDate(value)
        sessionStorage.setItem("fromDate", JSON.stringify(value));
    }

    const setToDateSession = (value: string) => {
        setToDate(value)
        sessionStorage.setItem("toDate", JSON.stringify(value));
    }

    const columns: GridColumns = [
        {
            field: 'dateTime',
            headerName: 'Date',
            valueGetter: ({ value }) => value && format(new Date(value + 'Z'), 'MM/dd/yyyy hh:mm:ss aa'),
            flex: 2,
            type: 'date',
        },
        {
            field: 'changeType',
            headerName: 'Action',
            flex: 1,
        },
        {
            field: 'changeBy',
            headerName: 'Changed By',
            flex: 2,
        },
        {
            field: 'primaryKey',
            headerName: 'MMID',
            flex: 2,
        },
        {
            field: 'displayName',
            headerName: 'Name',
            flex: 3,
        },
        {
            field: 'fieldName',
            headerName: 'Field',
            flex: 1,
        },
        {
            field: 'oldValue',
            headerName: 'Old Value',
            flex: 1,
        },
        {
            field: 'newValue',
            headerName: 'New Value',
            flex: 1,
        },
        {
            field: 'linkedType',
            headerName: 'Linked Type',
            flex: 1,
        },
        {
            field: 'oldLinkedValue',
            headerName: 'Old Linked Value',
            flex: 1,
        },
        {
            field: 'newLinkedValue',
            headerName: 'New Linked Value',
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const moneyManagerId = data?.items?.find(x => x.id === id)?.primaryKey;
                return [
                    <EditLinkCellItem rowId={id} path={`/money-manager/edit/${moneyManagerId}?backButtonPath=/money-manager/history/`} />
                ];
            },
        }
    ];

    return (
        <Paper>
            <Stack direction="column" padding={2} spacing={1}>
                <h1>Money Manager Change Log</h1>
                <Button variant="outlined" color="primary" component={Link} to="/money-manager" sx={{ flex: 1 }}>
                    Back
                </Button>
                <div className="filterWrapper">
                    <Stack className="filterGroup" direction="row" spacing={2}>
                        <TextField
                            sx={{ flex: 1 }}
                            type="date"
                            label="From Date"
                            InputLabelProps={{ shrink: true }}
                            value={fromDate}
                            onChange={(e) => setFromDateSession(e.target.value)}
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            type="date"
                            label="To Date"
                            InputLabelProps={{ shrink: true }}
                            value={toDate}
                            onChange={(e) => setToDateSession(e.target.value)}
                        />
                        <XDayButton setFromDate={setFromDateSession} setToDate={setToDateSession}>Today</XDayButton>
                        <XDayButton daysOffset={-1} setFromDate={setFromDateSession} setToDate={setToDateSession}>Yesterday</XDayButton>
                    </Stack>
                </div>

                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={data?.items ?? []}
                            getRowId={(row) => row.id ?? -1}
                            columns={columns}
                            rowCount={data?.total ?? 0}
                            onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
                            onPageChange={(page: number) => {
                                setPage(page);
                            }}
                            page={page}
                            pageSize={perPage}
                            sortModel={sortModel}
                            onSortModelChange={(model) => {
                                setSortModel(model);
                                sessionStorage.setItem("sortModel", JSON.stringify(model));
                            }}
                            autoHeight
                            density="compact"
                            sortingMode="server"
                            paginationMode="server"
                            disableColumnFilter
                            disableColumnMenu
                            loading={isLoading || isFetching}
                            onRowDoubleClick={(params) => {
                                const moneyManagerId = data?.items?.find(x => x.id === params.id)?.primaryKey;
                                navigate(`/money-manager/edit/${moneyManagerId}?backButtonPath=/money-manager/history/`)
                            }}
                            components={{
                                Toolbar: () => CustomDataGridToolbar({
                                    csvProps: {
                                        getData: () => moneyManagerGetMoneyManagerHistory({
                                            fromDate: debouncedFromDate,
                                            toDate: setExclusiveToDate(debouncedToDate),
                                            page: 0,
                                            perPage: MAX_32_BIT_INT,
                                            orderBy: (sortModel && sortModel[0] && sortModel[0].field) || '',
                                            orderByDirection: sortModel && sortModel[0] && sortModel[0].sort === 'asc' ? OrderByDirection.Asc : OrderByDirection.Desc,
                                        }),
                                        columns: columns,
                                        columnVisibility: columnVisibilityModel,
                                        reportNamePrefix: "MoneyManagerHistory",
                                    },
                                    includeColumns: true,
                                    includePagination: true,
                                })
                            }}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => {
                                setColumnVisibilityModel(newModel);
                                sessionStorage.setItem("columnVisibilityModel", JSON.stringify(newModel));
                            }}
                        />
                    </div>
                </div>
            </Stack>
        </Paper>
    );
};

export default MoneyManagerHistoryPage;