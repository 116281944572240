import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Divider, List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import FeedbackModal from '../areas/Feedback/FeedbackModal';

const CollapsedListItem = ({
    addDivider,
    main,
    links,
}: {
    addDivider?: boolean;
    main: React.ReactNode;
    links: React.ReactNode;
}) => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton onClick={handleClick}>
                {main}
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {links}
            </Collapse>
            {addDivider && <Divider sx={{ my: 1 }} />}
        </>
    );
};

const NavigationLink = ({ addDivider, text, link }: { text: string; link: string; addDivider?: boolean }) => (
    <>
        <ListItemButton>
            <NavLink
                to={link}
                style={{ textDecoration: 'none' }}
            >
                {text}
            </NavLink>
        </ListItemButton>
        {addDivider && <Divider sx={{ my: 1 }} />}
    </>
);

export const MainListItems = () => {
    return (
        <List>
            <CollapsedListItem
                addDivider
                main={<ListItemText primary="Preview Information" />}
                links={
                    <List
                        component="li"
                        disablePadding
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <NavigationLink text="Activity Recap" link="/preview/activity-recap" />
                        <NavigationLink text="MMID Detail" link="/preview/mmid" />
                        <NavigationLink text="Wires and Checks" link="/preview/wires-and-checks" />
                        <NavigationLink text="Activity Raw Data" link="/preview/activity-data" />
                        <NavigationLink text="LTID" link="/preview/ltid" />
                    </List>
                }
            />
            <NavigationLink addDivider text="Producer Payout" link="/producer-payout" />
            <NavigationLink addDivider text="Daily Recap, Wires and Checks" link="/daily-recap" />
            <NavigationLink addDivider text="Money Manager Information" link="/money-manager" />
            <CollapsedListItem
                addDivider
                main={<ListItemText primary="Data Maintenance" />}
                links={
                    <List
                        component="li"
                        disablePadding
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <NavigationLink text="Sub Accounts" link="/data/sub-accounts" />
                        <NavigationLink text="Stock Symbols" link="/data/stocks" />
                        <NavigationLink text="Employees" link="/data/employees" />
                        <NavigationLink text="Janus Exceptions" link="/data/exceptions" />
                        <NavigationLink text="Research Exclusions" link="/data/research-exclusions" />
                        <NavigationLink text="Research Inclusions" link="/data/research-inclusions" />
                        <NavigationLink text="Alpha Capture List" link="/data/alpha-capture-list" />
                        <NavigationLink text="Restricted List" link="/data/restricted-list" />
                        <NavigationLink text="Watch List" link="/data/watch-list" />
                    </List>
                }
            />
            <CollapsedListItem
                addDivider
                main={<ListItemText primary="Imports" />}
                links={
                    <List
                        component="li"
                        disablePadding
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <NavigationLink text="Daily Activity" link="/import/daily" />
                        <NavigationLink text="Fidessa 2PM Activity" link="/import/fidessa-activity" />
                        <NavigationLink text="Fidessa Trade Ahead" link="/import/fidessa-trade-ahead" />
                        <NavigationLink text="Restricted MCT Trades" link="/import/restricted-mct-trades" />
                        <NavigationLink text="Alpha Capture" link="/import/alpha" />
                    </List>
                }
            />
            <CollapsedListItem
                addDivider
                main={<ListItemText primary="Reports" />}
                links={
                    <List
                        component="li"
                        disablePadding
                        sx={{
                            paddingLeft: '25px',
                        }}
                    >
                        <NavigationLink text="Fidessa 2PM Activity" link="/report/fidessa-activity" />
                        <NavigationLink text="Symbol Reports" link="/report/symbols" />
                        <NavigationLink text="Trade Ahead" link="/report/trade-ahead" />
                        <NavigationLink text="Restricted MCT Trades" link="/report/restricted-trades" />
                        <NavigationLink text="Fidessa Reports" link="/report/fidessa-reports" />
                        <NavigationLink text="Daily Report Maintenance" link="/report/managed-reports" />
                        <NavigationLink text="Watchlist Trades" link="/report/watchlist-trades" />
                    </List>
                }
            />
            <FeedbackModal />
        </List>
    );
};
