import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { GridColumnVisibilityModel, GridColumns, GridValueGetterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

export type CsvDownloadLinkProps<T> = {
    getData: () => Promise<T>;
    columns: GridColumns;
    columnVisibility?: GridColumnVisibilityModel;
    reportNamePrefix?: string;
    reportName?: string;
    buttonFragment?: JSX.Element;
    props?: ButtonProps;
};

const CsvDownloadLink = <T extends { items?: any[] | undefined | null }>({ getData, columns, columnVisibility = {}, reportNamePrefix = "export", reportName, buttonFragment = <><SaveAltIcon /> Export</>, props }: CsvDownloadLinkProps<T>) => {

    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const headers = columns.map(item => ({ label: item.headerName || '', key: item.field })).filter((item) => columnVisibility[item.key] != false);

    useEffect(() => {
        if (data && data.length > 0) {
            const element: HTMLElement = document.getElementById('export') as HTMLElement;
            element.click();
        }
    }, [data]);

    return (
        <>
            <Button
                size='small'
                {...props}
                onClick={() => {
                    setLoading(true);
                    getData().then((value) => {
                        const mapped = value.items?.map((value, index, arr) => {
                            const item: any = {};
                            for (const column in columns) {
                                const getter = columns[column].valueGetter;
                                // This is absolutely a hack but it works
                                const adjustedFieldName = (columns[column].field.endsWith('ExportRaw')) ? 
                                    columns[column].field.replace('ExportRaw', '') :
                                    columns[column].field;
                                item[columns[column].field] =  (getter) ? 
                                        getter({ value: value[adjustedFieldName] } as GridValueGetterParams) : 
                                        value[adjustedFieldName];
                            }
                            return item;
                        });
                        setData(mapped ?? []);
                        setLoading(false)
                    }).catch(() => {
                        setData([]);
                    });
                }}
            >
                {loading ? <>Preparing Export <CircularProgress size={20} /></> : buttonFragment}
            </Button>
            <CSVLink
                style={{ display: 'none' }}
                filename={reportName ? reportName : `${reportNamePrefix}.${format(new Date(), 'yyyyMMdd')}.csv`}
                className='btn btn-primary'
                data={data}
                headers={headers}
                id='export'
            />
        </>);

}

export default CsvDownloadLink;