import { Button, Checkbox, FormControlLabel, FormGroup, Stack, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FilterResponse, VirtualizedFilterControlledDropdown } from '../../components/VirtualizedFilterDropdown';
import * as commissionapi from '../../services/commissionapi';

export type DailyRecapWiresChecksFormModel = {
    wiresChecksType?: FilterResponse;
    wiresChecksTypeId?: number | null;
    tradeDate?: string | null;
    action?: FilterResponse;
    actionId?: number | null;
    shares?: number | null;
    symbol?: FilterResponse;
    symbolId?: number | null;
    moneyManager?: FilterResponse;
    moneyManagerId?: string | null;
    producer?: FilterResponse;
    producerId?: string | null;
    subAccount?: FilterResponse;
    subAccountId?: number | null;
    pricePerShare?: number | null;
    commPerShare?: number | null;
    commission?: number;
    isAdjustment?: boolean;
    isExcluded?: boolean;
    adjustmentPayDate?: string | null;
    wiresChecksAccount?: string | null;
    vwap?: number | null;
    notes?: string | null;
};

type Props = {
    defaultValues: DailyRecapWiresChecksFormModel;
    onSubmit: (data: DailyRecapWiresChecksFormModel) => void;
    isNewForm: boolean;
};


const DailyRecapWiresChecksForm = ({ onSubmit, defaultValues, isNewForm }: Props) => {

    const { data: wiresChecksTypes, isLoading: wiresChecksTypesLoading } = commissionapi.useRecapsWiresChecksGetWiresChecksTypeFilters();
    const { data: actions, isLoading: actionsLoading } = commissionapi.useActionsGetActionFilters();
    const { data: symbols, isLoading: symbolsLoading } = commissionapi.useSymbolGetSymbolFilters();
    const { data: moneyManagers, isLoading: moneyManagersLoading } = commissionapi.useMoneyManagerGetMoneyManagerFilters();
    const { data: producers, isLoading: producersLoading } = commissionapi.useProducersGetProducerFilters({
        includeHouseProducers: true, //527 - In Access App, Daily Recap Wires and Checks => includes house
    });
    const { data: subAccounts, isLoading: subAccountsLoading } = commissionapi.useSubAccountGetSubAccountFilters();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
    } = useForm<DailyRecapWiresChecksFormModel>({
        defaultValues,
    });

    const HandleSubmitData = (data: DailyRecapWiresChecksFormModel) => {
        onSubmit(data);
    };

    const watchShares = watch(['shares']);
    const watchCommPerShare = watch(['commPerShare']);

    useEffect(() => {
        const shares = watchShares[0];
        const commPerShare = watchCommPerShare[0];
        if (shares && commPerShare) {
            setValue('commission', Number(shares) * Number(commPerShare));
        }
    }, [watchShares, watchCommPerShare, setValue]);

    if (wiresChecksTypesLoading || actionsLoading || symbolsLoading || producersLoading || moneyManagersLoading) return <div>Loading...</div>;

    if (defaultValues.wiresChecksType === undefined && defaultValues.wiresChecksTypeId) {
        const value = wiresChecksTypes?.find((x) => x.wiresChecksTypeId === defaultValues?.wiresChecksTypeId);
        setValue('wiresChecksType', { id: value?.wiresChecksTypeId, label: value?.name || '' });
    }
    if (defaultValues.action === undefined && defaultValues.actionId) {
        const value = actions?.find((x) => x.actionId === defaultValues?.actionId);
        setValue('action', { id: value?.actionId, label: value?.name || '' });
    }
    if (defaultValues.symbol === undefined && defaultValues.symbolId) {
        const value = symbols?.find((x) => x.symbolId === defaultValues?.symbolId);
        setValue('symbol', { id: value?.symbolId, label: value?.name || '' });
    }
    if (defaultValues.moneyManager === undefined && defaultValues.moneyManagerId) {
        const value = moneyManagers?.find((x) => x.moneyManagerId === defaultValues?.moneyManagerId);
        setValue('moneyManager', { id: value?.moneyManagerId || -1, label: value?.displayName || '' });
    }
    if (defaultValues.producer === undefined && defaultValues.producerId) {
        const value = producers?.find((x) => x.producerId === defaultValues?.producerId);
        setValue('producer', { id: value?.producerId || -1, label: value?.registeredRepName || '' });
    }
    if (defaultValues.subAccount === undefined && defaultValues.subAccountId) {
        const value = subAccounts?.find((x) => x.subAccountId === defaultValues?.subAccountId);
        setValue('subAccount', { id: value?.subAccountId || -1, label: value?.name || '' });
    }

    return (
        <form onSubmit={handleSubmit(HandleSubmitData)}>
            <Stack direction="column" padding={2} spacing={1}>
                <Stack direction="row" spacing={2}>
                    <VirtualizedFilterControlledDropdown
                        control={control}
                        name="wiresChecksType"
                        label="Wire Type"
                        options={wiresChecksTypes?.map((x) => ({ label: x.name || '', id: x.wiresChecksTypeId })) ?? []}
                    />
                    <TextField
                        type="date"
                        sx={{ flex: 1 }}
                        label="Trade Date"
                        error={!!errors.tradeDate}
                        InputLabelProps={{ shrink: true }}
                        {...register('tradeDate')}
                    />
                    <VirtualizedFilterControlledDropdown
                        control={control}
                        name="action"
                        label="Buy/Sell"
                        options={actions?.map((x) => ({ label: x.name || '', id: x.actionId })) ?? []}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <VirtualizedFilterControlledDropdown
                        control={control}
                        name="symbol"
                        label="Stock Symbol"
                        options={symbols?.map((x) => ({ label: x.ticker || '', id: x.symbolId })) ?? []}
                    />
                    <VirtualizedFilterControlledDropdown
                        control={control}
                        name="moneyManager"
                        label="Money Manager"
                        options={moneyManagers?.map((x) => ({ label: x.displayName || '', id: x.moneyManagerId || -1 })) ?? []}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <VirtualizedFilterControlledDropdown
                        control={control}
                        name="producer"
                        label="Producer"
                        options={producers?.map((x) => ({ label: `${x.firstName} ${x.lastName}`, id: x.producerId ?? -1, searchText: x.registeredRepName })) ?? []}
                    />
                    <VirtualizedFilterControlledDropdown
                        control={control}
                        name="subAccount"
                        label="SubAccount"
                        options={subAccounts?.map((x) => ({ label: x.name || '', id: x.subAccountId || -1 })) ?? []}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1 }}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        label="Shares"
                        {...register('shares', {
                            valueAsNumber: true,
                            validate: (value) =>
                                !(value) || (value > 0) || 'shares must be greater than 0',
                        })}
                        error={!!errors.shares}
                        helperText={!!errors.shares ? 'Value must be greater than 0' : undefined}
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        label="Price Per Share"
                        {...register('pricePerShare', {
                            valueAsNumber: true,
                            validate: (value) =>
                                !(value) || (value > 0) || 'price per share must be greater than 0',
                        })}
                        error={!!errors.pricePerShare}
                        helperText={!!errors.pricePerShare ? 'Value must be greater than 0' : undefined}
                        inputProps={{
                            maxLength: 13,
                            step: 'any'
                        }}
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        label="Commission Per Share"
                        {...register('commPerShare', {
                            valueAsNumber: true,
                            validate: (value) =>
                                !(value) || (value > 0) || 'commission per share must be greater than 0',
                        })}
                        error={!!errors.commPerShare}
                        helperText={!!errors.commPerShare ? 'Value must be greater than 0' : undefined}
                        inputProps={{
                            maxLength: 13,
                            step: 'any'
                        }}
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        label="Commission"
                        {...register('commission', {
                            valueAsNumber: true,
                            validate: (value) =>
                                !(value) || (value > 0) || 'commission must be greater than 0',
                        })}
                        error={!!errors.commission}
                        helperText={!!errors.commission ? 'Value must be greater than 0' : undefined}
                        inputProps={{
                            maxLength: 13,
                            step: 'any'
                        }}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1 }}
                        label="WC Account Number"
                        inputProps={{
                            maxLength: 10,
                        }}
                        error={!!errors.wiresChecksAccount}
                        {...register('wiresChecksAccount')}
                    />
                    <TextField
                        type="date"
                        sx={{ flex: 1 }}
                        label="Adjustment Pay Date"
                        error={!!errors.adjustmentPayDate}
                        InputLabelProps={{ shrink: true }}
                        {...register('adjustmentPayDate')}
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        label="VWAP"
                        {...register('vwap')}
                        error={!!errors.vwap}
                        helperText={!!errors.vwap}
                        inputProps={{
                            maxLength: 13,
                            step: 'any'
                        }}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1 }}
                        multiline
                        rows={3}
                        label="Notes"
                        error={!!errors.notes}
                        {...register('notes')}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <FormGroup>
                        <FormControlLabel
                            disabled={!isNewForm}
                            control={
                                <Checkbox defaultChecked={!!defaultValues.isAdjustment} {...register('isAdjustment')} />
                            }
                            label="Adjustment"
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            disabled={!isNewForm}
                            control={
                                <Checkbox defaultChecked={!!defaultValues.isExcluded} {...register('isExcluded')} />
                            }
                            label="Excluded"
                        />
                    </FormGroup>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Save
                    </Button>
                    <Button variant="outlined" color="primary" component={Link} to="/daily-recap" fullWidth>
                        Back
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};

export default DailyRecapWiresChecksForm;