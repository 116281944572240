import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { acquireAccessToken } from './../AuthHelpers';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const AXIOS_INSTANCE = Axios.create({ baseURL }); // use your own URL here or environment variable

export const customInstance = async <T>(config: AxiosRequestConfig): Promise<T> => {
    const source = Axios.CancelToken.source();

    const token = await acquireAccessToken();
    const headers = {
        ...config.headers,
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    const promise = AXIOS_INSTANCE({ ...config, headers, cancelToken: source.token }).then(({ data }) => data);

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled');
    };

    return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

// In case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
// export type BodyType<BodyData> = CamelCase<BodyType>
export type BodyType<BodyData> = BodyData & { headers?: any };
