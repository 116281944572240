import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import DailyRecapWiresChecksPageEdit from '../areas/DailyRecapWiresChecks/DailyRecapWiresChecksPageEdit';
import MoneyManagerDetailsHistoryPage from '../areas/MoneyManager/MoneyManagerDetailsHistoryPage';
import MoneyManagerHistoryPage from '../areas/MoneyManager/MoneyManagerHistoryPage';

const DashboardPage = React.lazy(() => import('../areas/Dashboard/Dashboard'));

const DailyRecapWiresChecksPage = React.lazy(() => import('../areas/DailyRecapWiresChecks/DailyRecapWiresChecksPage'));
const DailyRecapWiresChecksPageNew = React.lazy(() => import('../areas/DailyRecapWiresChecks/DailyRecapWiresChecksPageNew'));

const MoneyManagerPage = React.lazy(() => import('../areas/MoneyManager/MoneyManagerPage'));
const MoneyManagerPageEdit = React.lazy(() => import('../areas/MoneyManager/MoneyManagerPageEdit'));
const MoneyManagerPageNew = React.lazy(() => import('../areas/MoneyManager/MoneyManagerPageNew'));

const ActivityDataPage = React.lazy(() => import('../areas/PreviewInformation/ActivityDataPage'));
const ActivityRecapPage = React.lazy(() => import('../areas/PreviewInformation/ActivityRecapPage'));
const LtidPage = React.lazy(() => import('../areas/PreviewInformation/LtidPage'));
const MmidPage = React.lazy(() => import('../areas/PreviewInformation/MmidPage'));
const PreviewInformationPage = React.lazy(() => import('../areas/PreviewInformation/PreviewInformation'));
const WiresAndChecksPage = React.lazy(() => import('../areas/PreviewInformation/WiresAndChecksPage'));

const ProducerPayoutPage = React.lazy(() => import('../areas/ProducerPayout/ProducerPayoutPage'));

const DataMaintenancePage = React.lazy(() => import('../areas/DataMaintenance/DataMaintenancePage'));
const SubAccountsPage = React.lazy(() => import('../areas/DataMaintenance/SubAccounts/SubAccountPage'));
const SymbolsPage = React.lazy(() => import('../areas/DataMaintenance/Symbols/SymbolsPage'));
const SymbolsPageNew = React.lazy(() => import('../areas/DataMaintenance/Symbols/SymbolsPageNew'));
const EmployeesPage = React.lazy(() => import('../areas/DataMaintenance/Employees/EmployeesPage'));
const EmployeesPageNew = React.lazy(() => import('../areas/DataMaintenance/Employees/EmployeesPageNew'));
const EmployeesPageEdit = React.lazy(() => import('../areas/DataMaintenance/Employees/EmployeesPageEdit'));

const JanusExceptionsPage = React.lazy(() => import('../areas/DataMaintenance/JanusExceptions/ExceptionsPage'));
const JanusExceptionsPageNew = React.lazy(() => import('../areas/DataMaintenance/JanusExceptions/ExceptionsPageNew'));
const ExclusionsPage = React.lazy(() => import('../areas/DataMaintenance/ResearchExclusions/ExclusionsPage'));
const ExclusionsPageNew = React.lazy(() => import('../areas/DataMaintenance/ResearchExclusions/ExclusionsPageNew'));
const InclusionsPage = React.lazy(() => import('../areas/DataMaintenance/ResearchInclusions/InclusionsPage'));
const InclusionsPageNew = React.lazy(() => import('../areas/DataMaintenance/ResearchInclusions/InclusionsPageNew'));
const ManagedReports = React.lazy(() => import('../areas/DataMaintenance/ManagedReports/ManagedReportsPage'));
const ManagedReportsNew = React.lazy(() => import('../areas/DataMaintenance/ManagedReports/ManagedReportsPageNew'));
const AlphaCaptureListPage = React.lazy(() => import('../areas/DataMaintenance/ListPage/AlphaCaptureListPage'));
const AlphaCaptureListPageNew = React.lazy(() => import('../areas/DataMaintenance/ListPage/AlphaCaptureListPageNew'));
const RestrictedListPage = React.lazy(() => import('../areas/DataMaintenance/ListPage/RestrictedListPage'));
const RestrictedListPageNew = React.lazy(() => import('../areas/DataMaintenance/ListPage/RestrictedListPageNew'));
const WatchListPage = React.lazy(() => import('../areas/DataMaintenance/ListPage/WatchListPage'));
const WatchListPageNew = React.lazy(() => import('../areas/DataMaintenance/ListPage/WatchListPageNew'));
const AlphaCaptureListPageEdit = React.lazy(() => import('../areas/DataMaintenance/ListPage/AlphaCaptureListPageEdit'));
const RestrictedListPageEdit = React.lazy(() => import('../areas/DataMaintenance/ListPage/RestrictedListPageEdit'));
const WatchListPageEdit = React.lazy(() => import('../areas/DataMaintenance/ListPage/WatchListPageEdit'));

const ImportsPage = React.lazy(() => import('../areas/Imports/ImportsPage'));
const DailyActivityPage = React.lazy(() => import('../areas/Imports/DailyActivityPage'));
const DailyFidessaPage = React.lazy(() => import('../areas/Imports/DailyFidessaPage'));
const FidessaTransactionsPage = React.lazy(() => import('../areas/Imports/FidessaTransactionsPage'));
const SchwabTransactionsPage = React.lazy(() => import('../areas/Imports/SchwabTransactionsPage'));
const AlphaCapturesPage = React.lazy(() => import('../areas/Imports/AlphaCapturesPage'));

const ReportsPage = React.lazy(() => import('../areas/Reports/ReportsPage'));
const FidessaActivityPage = React.lazy(() => import('../areas/Reports/FidessaActivityPage'));
const SymbolReportsPage = React.lazy(() => import('../areas/Reports/SymbolReportsPage'));
const TradeAheadPage = React.lazy(() => import('../areas/Reports/TradeAheadPage'));
const RestrictedMCTPage = React.lazy(() => import('../areas/Reports/RestrictedMCTPage'));
const FidessaReportsPage = React.lazy(() => import('../areas/Reports/FidessaReports/FidessaReportsPage'));
const WatchlistTradesPage = React.lazy(() => import('../areas/Reports/WatchlistTradesPage'));

const CommissionAppRoutes = () => (
    <Suspense fallback={<p>Loading Component ... </p>}>
        <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/preview" element={<PreviewInformationPage />}>
                <Route path="activity-recap" element={<ActivityRecapPage />} />
                <Route path="mmid" element={<MmidPage />} />
                <Route path="wires-and-checks" element={<WiresAndChecksPage />} />
                <Route path="activity-data" element={<ActivityDataPage />} />
                <Route path="ltid" element={<LtidPage />} />
            </Route>
            <Route path="/producer-payout" element={<ProducerPayoutPage />} />
            <Route path="/daily-recap">
                <Route index element={<DailyRecapWiresChecksPage />} />
                <Route path="new" element={<DailyRecapWiresChecksPageNew />} />
                <Route path="edit/:id" element={<DailyRecapWiresChecksPageEdit />} />
            </Route>

            <Route path="/money-manager">
                <Route index element={<MoneyManagerPage />} />
                <Route path="new" element={<MoneyManagerPageNew />} />
                <Route path="edit/:id" element={<MoneyManagerPageEdit />} />
                <Route path="history/:id" element={<MoneyManagerDetailsHistoryPage />} />
                <Route path="history" element={<MoneyManagerHistoryPage />} />
            </Route>

            <Route path="/data" element={<DataMaintenancePage />}>
                <Route path="sub-accounts">
                    <Route index element={<SubAccountsPage />} />
                </Route>

                <Route path="stocks">
                    <Route index element={<SymbolsPage />} />
                    <Route path="new" element={<SymbolsPageNew />} />
                </Route>
                <Route path="employees">
                    <Route index element={<EmployeesPage />} />
                    <Route path="new" element={<EmployeesPageNew />} />
                    <Route path="edit/:id" element={<EmployeesPageEdit />} />
                </Route>
                <Route path="exceptions">
                    <Route index element={<JanusExceptionsPage />} />
                    <Route path="new" element={<JanusExceptionsPageNew />} />
                </Route>
                <Route path="research-exclusions">
                    <Route index element={<ExclusionsPage />} />
                    <Route path="new" element={<ExclusionsPageNew />} />
                </Route>
                <Route path="research-inclusions">
                    <Route index element={<InclusionsPage />} />
                    <Route path="new" element={<InclusionsPageNew />} />
                </Route>
                <Route path="watch-list">
                    <Route index element={<WatchListPage />} />
                    <Route path="new" element={<WatchListPageNew />} />{' '}
                    <Route path="edit/:id" element={<WatchListPageEdit />} />
                </Route>
                <Route path="restricted-list">
                    <Route index element={<RestrictedListPage />} />
                    <Route path="new" element={<RestrictedListPageNew />} />
                    <Route path="edit/:id" element={<RestrictedListPageEdit />} />
                </Route>
                <Route path="alpha-capture-list">
                    <Route index element={<AlphaCaptureListPage />} />
                    <Route path="new" element={<AlphaCaptureListPageNew />} />{' '}
                    <Route path="edit/:id" element={<AlphaCaptureListPageEdit />} />
                </Route>
            </Route>

            <Route path="/import" element={<ImportsPage />}>
                <Route path="daily" element={<DailyActivityPage />} />
                <Route path="fidessa-activity" element={<DailyFidessaPage />} />
                <Route path="fidessa-trade-ahead" element={<FidessaTransactionsPage />} />
                <Route path="restricted-mct-trades" element={<SchwabTransactionsPage />} />
                <Route path="alpha" element={<AlphaCapturesPage />} />
            </Route>

            <Route path="/report" element={<ReportsPage />}>
                <Route path="fidessa-activity" element={<FidessaActivityPage />} />
                <Route path="symbols" element={<SymbolReportsPage />} />
                <Route path="trade-ahead" element={<TradeAheadPage />} />
                <Route path="restricted-trades" element={<RestrictedMCTPage />} />
                <Route path="fidessa-reports" element={<FidessaReportsPage />} />
                <Route path="managed-reports">
                    <Route index element={<ManagedReports />} />
                    <Route path="new" element={<ManagedReportsNew />} />
                </Route>
                <Route path="watchlist-trades" element={<WatchlistTradesPage />} />
            </Route>
        </Routes>
    </Suspense>
);

export default CommissionAppRoutes;
