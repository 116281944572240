import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/system';
import { format } from 'date-fns';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import { useMoneyManagerGetHistory } from '../../services/commissionapi';
import { MoneyManagerResponse, PayoutHistory } from '../../services/model';

interface IIndexable {
    [key: string]: any;
}

const MoneyManagerDetailsHistoryPage = () => {
    const { id } = useParams<{ id: string }>();
    const [fromDate, setFromDate] = useState<string>('');
    const debouncedFromDate = useDebounce(fromDate, 500);

    const { data, isLoading, isFetching } = useMoneyManagerGetHistory(id as string, { fromDate: debouncedFromDate });
    const editHref = `/money-manager/edit/${id}`;

    const currentMoneyManager = data?.moneyManagerHistories?.at(0);
    const misMatch = {
        backgroundColor: 'DarkOrange',
        color: 'Black',
    }

    const getMoneyManagerHistoriesFormatted = (moneyManagerHistories: any) => {
        if(moneyManagerHistories) {
            moneyManagerHistories.forEach(function(dataRecord: any) {
                dataRecord.assetsUnderManagementFormatted = dataRecord.assetsUnderManagement?.toLocaleString('en-US', { style: 'decimal' });
                dataRecord.riskParameterOrderFormatted = dataRecord.riskParameterOrder?.toLocaleString('en-US', { style: 'decimal' });
                dataRecord.riskParameterMoneyManagerFormatted = dataRecord.riskParameterMoneyManager?.toLocaleString('en-US', { style: 'decimal' });
            });
            return moneyManagerHistories;
        }
        else {
            return [];
        }
    }

    const getGroupedPayoutHistories = (payoutHistories: PayoutHistory[] | null | undefined) => {
        //Group the payout histories into a [{"payoutId": ..., "payoutLabel": ..., "payoutHistories": [...]}] structure to loop through
        let output: any = [];
        let distinctPayoutIds = data?.payoutHistories?.map((x: any) => x.payoutId)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index) //Remove duplicates
            .sort((a: any, b: any) => a - b); //Sort by Payout ID

        distinctPayoutIds?.forEach((distinctPayoutId: any) => {
            const latestPayout = data?.payoutHistories //Get the latest payout history for the producer name to use for the accordion label
                ?.filter((x: any) => x.payoutId === distinctPayoutId)
                // @ts-ignore
                .sort((a, b) => new Date(b.validFrom) - new Date(a.validFrom))
                [0];
                
            let distinctPayoutRecord: any = {};
            distinctPayoutRecord.payoutId = distinctPayoutId; 
            distinctPayoutRecord.payoutLabel = 'Coverage - ' + ((latestPayout?.producerName) ? latestPayout?.producerName : 'Unknown');
            distinctPayoutRecord.payoutHistories = data?.payoutHistories?.filter((x: any) => x.payoutId === distinctPayoutId);
            distinctPayoutRecord.payoutHistories.forEach(function(payoutHistory: any) { //Add formatted date fields to the payout history for display, removing the time so it matches the coverage menu & doesn't show false-positive changes from time-only changes
                payoutHistory.startDateFormatted = payoutHistory?.startDate ? format(new Date(payoutHistory?.startDate + 'Z'), 'MM/dd/yyyy') : '';
                payoutHistory.endDateFormatted = payoutHistory?.endDate ? format(new Date(payoutHistory?.endDate + 'Z'), 'MM/dd/yyyy') : '';
              });
            output.push(distinctPayoutRecord);
        });
        return output;
    }

    function getRow(items: any[], title: string) {
        return (
            <TableRow>
                <TableCell variant='head'>{title}</TableCell>
                {items?.map((value, index, array) => {
                    const change = index + 1 < array.length && array[index + 1] !== value;
                    return (
                        <TableCell key={index} sx={change ? misMatch : undefined}>
                            {typeof value == "boolean" ? <Checkbox defaultChecked={value} disabled /> : <>{value}</>}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    return (
        <>
            <Stack direction="column" padding={2} spacing={2}>
                <Paper>
                    <Stack direction="row" padding={2} spacing={1}>
                        <TextField
                            sx={{ flex: 2 }}
                            type="date"
                            label="From Date"
                            InputLabelProps={{ shrink: true }}
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                        <Button variant="outlined" color="primary" component={Link} to="/money-manager" sx={{ flex: 1 }}>
                            Back
                        </Button>
                        <Button variant="contained" color="primary" href={editHref} sx={{ flex: 1 }}>
                            Edit
                        </Button>
                    </Stack>
                </Paper>
                <div>
                    {(() => {
                        const moneyManagerHistoriesFormatted = getMoneyManagerHistoriesFormatted(data?.moneyManagerHistories);
                        return moneyManagerHistoriesFormatted && (
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary id="accordion-header-money-manager" aria-controls="accordion-content-money-manager" expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Money Manager</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                <TableCell variant='head' width={200} >Date</TableCell>
                                                    {moneyManagerHistoriesFormatted?.map((row: any, rowIndex: number) => {
                                                        const date = row?.validFrom ? format(new Date(row.validFrom + 'Z'), 'MM/dd/yyyy') : '';
                                                        const time = row?.validFrom ? format(new Date(row.validFrom + 'Z'), 'hh:mm:ss aa') : '';
                                                        return (
                                                            <TableCell key={rowIndex} variant='head' width={200}>
                                                                <Stack direction="column">
                                                                    <div>{date}</div>
                                                                    <div>{time}</div>
                                                                </Stack>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.updatedBy) || [], 'Changed By')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isInactive) || [], 'Inactive')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.moneyManagerId) || [], 'MMID')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.craigHallumId) || [], 'CH ID')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.name) || [], 'Name')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.displayName) || [], 'Display Name')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.address1) || [], 'Address 1')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.address2) || [], 'Address 2')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.address3) || [], 'Address 3')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.city) || [], 'City')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.stateOrProvince) || [], 'StateProvince')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.postalCode) || [], 'Postal Code')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.districtOrCounty) || [], 'County/District')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.countryCode) || [], 'Country')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.fidessaId) || [], 'Fidessa ID')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.fdid) || [], 'FDID')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.ltid) || [], 'LTID')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isEmployee) || [], 'Employee')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.assetsUnderManagementFormatted) || [], 'Asset Under Management')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.riskParameterOrderFormatted) || [], 'Risk Parameter: Order')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.riskParameterMoneyManagerFormatted) || [], 'Risk Parameter: MM')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.accountTypeName) || [], 'Account Type')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.institution) || [], 'Institution')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.lei) || [], 'LEI')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.symbolName) || [], 'Symbol Name')}
                                                {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.tinEin) || [], 'TIN/EIN')}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Accordion defaultExpanded={false}>
                                        <AccordionSummary id="accordion-header-additional" aria-controls="accordion-content-additional" expandIcon={<ExpandMoreIcon />}  >
                                            <Typography>Additional Information</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                        <TableCell variant='head' width={200} >Date</TableCell>
                                                            {moneyManagerHistoriesFormatted?.map((row: any, rowIndex: number) => {
                                                                const date = row?.validFrom ? format(new Date(row.validFrom + 'Z'), 'MM/dd/yyyy') : '';
                                                                const time = row?.validFrom ? format(new Date(row.validFrom + 'Z'), 'hh:mm:ss aa') : '';
                                                                return (
                                                                    <TableCell key={rowIndex} variant='head' width={200}>
                                                                        <Stack direction="column">
                                                                            <div>{date}</div>
                                                                            <div>{time}</div>
                                                                        </Stack>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isSubAccount) || [], 'Sub Account')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.regulationsPapersNeeded) || [], 'Regulation Papers Needed')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isRetailDVP) || [], 'Retail DVP')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isQualified) || [], 'Qualified')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isFamilyOffice) || [], 'Family Office')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isBanking) || [], 'Banking')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.isDeListed) || [], 'Delisted')}
                                                        {getRow(moneyManagerHistoriesFormatted?.map((x: any) => x.primaryPhone) || [], 'Primary Phone')}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })()}
                    {getGroupedPayoutHistories(data?.payoutHistories).map((groupedPayoutHistory: any) => {
                        return (
                            <Accordion key={groupedPayoutHistory.payoutId}>
                                <AccordionSummary id="accordion-header-payout" aria-controls="accordion-content-payout" expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{groupedPayoutHistory.payoutLabel}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell variant='head' width={200} >Date</TableCell>
                                                    {groupedPayoutHistory.payoutHistories?.map((row: any, rowIndex: number) => {
                                                        const date = row?.validFrom ? format(new Date(row.validFrom + 'Z'), 'MM/dd/yyyy') : '';
                                                        const time = row?.validFrom ? format(new Date(row.validFrom + 'Z'), 'hh:mm:ss aa') : '';
                                                        return (
                                                            <TableCell key={rowIndex} variant='head' width={200}>
                                                                <Stack direction="column">
                                                                    <div>{date}</div>
                                                                    <div>{time}</div>
                                                                </Stack>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.updatedBy) || [], 'Changed By')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.isInactive) || [], 'Inactive')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.producerId) || [], 'Producer ID')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.producerName) || [], 'Producer Name')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.producerCode) || [], 'Producer Code')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.bcProducerId) || [], 'BC Producer ID')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.accountId) || [], 'Account ID')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.craigHallumId) || [], 'Craig Hallum ID')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.symbolId) || [], 'Symbol ID')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.startDateFormatted ) || [], 'Start Date')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.endDateFormatted) || [], 'End Date')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.payoutMember) || [], 'Payout Member')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.split) || [], 'Split')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.transferCoverage) || [], 'Transfer Coverage')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.territory) || [], 'Territory')}
                                                {getRow(groupedPayoutHistory.payoutHistories?.map((x: any) => x.isDeleted) || [], 'Is Deleted')}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            </Stack>
        </>
    );
};

export default MoneyManagerDetailsHistoryPage;