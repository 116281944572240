/**
 * Generated by orval
 * Do not edit manually.
 * CraigHallum.CommissionApp.API
 * OpenAPI spec version: 0.0.1
 */

export type OrderByDirection = typeof OrderByDirection[keyof typeof OrderByDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderByDirection = {
  Asc: 'Asc',
  Desc: 'Desc',
} as const;
