/**
 * Generated by orval
 * Do not edit manually.
 * CraigHallum.CommissionApp.API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AccountTypeResponse,
  ActionResponse,
  ActivitiesUploadBody,
  CountryResponse,
  StateResponse,
  AdjustmentCountResponse,
  AlphaCapturesUploadBody,
  EmployeeGroupResponse,
  EmployeeResponse,
  EmployeesGetEmployeeFiltersParams,
  EmployeeResponsePaginatedResponse,
  EmployeesGetParams,
  EmployeeUpdateModel,
  EmployeeCreateModel,
  DeletedResponse,
  DownloadResponse,
  FidessaActivitiesUploadBody,
  FidessaActivityResponsePaginatedResponse,
  FidessaActivitiesGetFidessaActivityReportParams,
  FidessaReportsDownloadParams,
  FidessaReportsDownloadDatesParams,
  FidessaReportResponsePaginatedResponse,
  FidessaReportsGetParams,
  FidessaReportResponse,
  FidessaReportUpsertRequest,
  FidessaTransactionsUploadBody,
  JanusExceptionResponsePaginatedResponse,
  JanusExceptionsGetParams,
  JanusExceptionResponse,
  JanusExceptionUpdateModel,
  JanusExceptionCreateModel,
  ManagedReportResponsePaginatedResponse,
  ManagedReportsGetParams,
  ManagedReportResponse,
  ManagedReportCreateRequest,
  ManagedReportUpdateRequest,
  MoneyManagerBaseResponse,
  MoneyManagerResponsePaginatedResponse,
  MoneyManagerGetParams,
  MoneyManagerResponse,
  MoneyManagerUpdateModel,
  MoneyManagerCreateModel,
  PayoutResponse,
  MoneyManagerGetHistoryResponse,
  MoneyManagerGetHistoryParams,
  SettlementContactResponse,
  SettlementContactRequest,
  HistoryResponsePaginatedResponse,
  MoneyManagerGetMoneyManagerHistoryParams,
  NewMmidResponsePaginatedResponse,
  MoneyManagerGetNewMmidParams,
  PayoutResponsePaginatedResponse,
  PayoutGetParams,
  PayoutUpdateModel,
  PayoutCreateModel,
  MmidDetailActivityPaginatedResponse,
  PreviewInformationMoneyManagerDetailActivityParams,
  WireAndChecksResultPaginatedResponse,
  PreviewInformationWiresAndChecksParams,
  LtidResultPaginatedResponse,
  PreviewInformationLtidParams,
  ActivityResultPaginatedResponse,
  PreviewInformationRawActivityParams,
  ActivityRecapResponsePaginatedResponse,
  PreviewInformationGetActivityRecapParams,
  PreviewInformationDownloadActivityRecapParams,
  ProducerResponse,
  ProducersGetProducerFiltersParams,
  ProducerResponsePaginatedResponse,
  ProducersGetParams,
  ProducerUpdateModel,
  ProducerCreateModel,
  RecapsWiresAndChecksResponsePaginatedResponse,
  RecapsWiresChecksIndexParams,
  RecapsWiresAndChecksResponse,
  RecapsWiresAndChecksCreateModel,
  WiresChecksTypeResponse,
  RecapsWiresAndChecksUpdateModel,
  Base64FileResult,
  ResearchExclusionResponsePaginatedResponse,
  ResearchExclusionGetParams,
  ResearchExclusionResponse,
  ResearchExclusionUpdateModel,
  ResearchExclusionCreateModel,
  ResearchInclusionResponsePaginatedResponse,
  ResearchInclusionGetParams,
  ResearchInclusionResponse,
  ResearchInclusionUpdateModel,
  ResearchInclusionCreateModel,
  RestrictedSchwabDataResponsePaginatedResponse,
  RestrictedSchwabDataGetRestrictedSchwabDataAggregateParams,
  RestrictedSchwabDataDownloadRestrictedSchwabDataParams,
  EmailDownloadResponse,
  RestrictedSchwabDataEmailRestrictedSchwabDataParams,
  RestrictionTypeResponse,
  SchwabTransactionsUploadBody,
  SubAccountResponsePaginatedResponse,
  SubAccountGetParams,
  SubAccountResponse,
  SubAccountUpdateModel,
  SubAccountCreateModel,
  SubAccountFilterResponse,
  SymbolResponsePaginatedResponse,
  SymbolGetParams,
  SymbolResponse,
  SymbolUpdateModel,
  SymbolCreateModel,
  SymbolFilterResponse,
  SymbolGroupResponse,
  SymbolSubGroupResponse,
  SymbolListResponse,
  SymbolRestrictionResponsePaginatedResponse,
  SymbolRestrictionGetParams,
  SymbolRestrictionResponse,
  SymbolRestrictionUpdateModel,
  SymbolRestrictionCreateModel,
  TradeAheadResponsePaginatedResponse,
  TradeAheadGetTradeAheadReportsParams,
  TradeAheadDownloadTradeAheadParams,
  ResponsePaginatedResponse,
  GetApiWatchlistTradesParams,
  WiresChecksResponsePaginatedResponse,
  WiresChecksIndexParams
} from './model'
import { customInstance } from '../query/custom-instance'
import type { ErrorType, BodyType } from '../query/custom-instance'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const accountTypesGetAccountTypeFilters = (
    
 signal?: AbortSignal
) => {
      return customInstance<AccountTypeResponse[]>(
      {url: `/AccountTypes`, method: 'get', signal
    },
      );
    }
  

export const getAccountTypesGetAccountTypeFiltersQueryKey = () => [`/AccountTypes`];

    
export type AccountTypesGetAccountTypeFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof accountTypesGetAccountTypeFilters>>>
export type AccountTypesGetAccountTypeFiltersQueryError = ErrorType<unknown>

export const useAccountTypesGetAccountTypeFilters = <TData = Awaited<ReturnType<typeof accountTypesGetAccountTypeFilters>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountTypesGetAccountTypeFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAccountTypesGetAccountTypeFiltersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountTypesGetAccountTypeFilters>>> = ({ signal }) => accountTypesGetAccountTypeFilters(signal);

  const query = useQuery<Awaited<ReturnType<typeof accountTypesGetAccountTypeFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const actionsGetActionFilters = (
    
 signal?: AbortSignal
) => {
      return customInstance<ActionResponse[]>(
      {url: `/Actions`, method: 'get', signal
    },
      );
    }
  

export const getActionsGetActionFiltersQueryKey = () => [`/Actions`];

    
export type ActionsGetActionFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof actionsGetActionFilters>>>
export type ActionsGetActionFiltersQueryError = ErrorType<unknown>

export const useActionsGetActionFilters = <TData = Awaited<ReturnType<typeof actionsGetActionFilters>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof actionsGetActionFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getActionsGetActionFiltersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof actionsGetActionFilters>>> = ({ signal }) => actionsGetActionFilters(signal);

  const query = useQuery<Awaited<ReturnType<typeof actionsGetActionFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const activitiesUpload = (
    activitiesUploadBody: BodyType<ActivitiesUploadBody>,
 ) => {const formData = new FormData();
if(activitiesUploadBody.file !== undefined) {
 formData.append('file', activitiesUploadBody.file)
 }

      return customInstance<void>(
      {url: `/Activities/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


    export type ActivitiesUploadMutationResult = NonNullable<Awaited<ReturnType<typeof activitiesUpload>>>
    export type ActivitiesUploadMutationBody = BodyType<ActivitiesUploadBody>
    export type ActivitiesUploadMutationError = ErrorType<unknown>

    export const useActivitiesUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activitiesUpload>>, TError,{data: BodyType<ActivitiesUploadBody>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activitiesUpload>>, {data: BodyType<ActivitiesUploadBody>}> = (props) => {
          const {data} = props ?? {};

          return  activitiesUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof activitiesUpload>>, TError, {data: BodyType<ActivitiesUploadBody>}, TContext>(mutationFn, mutationOptions)
    }
    
export const addressGetCountries = (
    
 signal?: AbortSignal
) => {
      return customInstance<CountryResponse[]>(
      {url: `/Address/countries`, method: 'get', signal
    },
      );
    }
  

export const getAddressGetCountriesQueryKey = () => [`/Address/countries`];

    
export type AddressGetCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof addressGetCountries>>>
export type AddressGetCountriesQueryError = ErrorType<unknown>

export const useAddressGetCountries = <TData = Awaited<ReturnType<typeof addressGetCountries>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof addressGetCountries>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAddressGetCountriesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof addressGetCountries>>> = ({ signal }) => addressGetCountries(signal);

  const query = useQuery<Awaited<ReturnType<typeof addressGetCountries>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const addressGetStates = (
    
 signal?: AbortSignal
) => {
      return customInstance<StateResponse[]>(
      {url: `/Address/states`, method: 'get', signal
    },
      );
    }
  

export const getAddressGetStatesQueryKey = () => [`/Address/states`];

    
export type AddressGetStatesQueryResult = NonNullable<Awaited<ReturnType<typeof addressGetStates>>>
export type AddressGetStatesQueryError = ErrorType<unknown>

export const useAddressGetStates = <TData = Awaited<ReturnType<typeof addressGetStates>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof addressGetStates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAddressGetStatesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof addressGetStates>>> = ({ signal }) => addressGetStates(signal);

  const query = useQuery<Awaited<ReturnType<typeof addressGetStates>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const adjustmentsGetAdjustmentCount = (
    
 signal?: AbortSignal
) => {
      return customInstance<AdjustmentCountResponse>(
      {url: `/Adjustments`, method: 'get', signal
    },
      );
    }
  

export const getAdjustmentsGetAdjustmentCountQueryKey = () => [`/Adjustments`];

    
export type AdjustmentsGetAdjustmentCountQueryResult = NonNullable<Awaited<ReturnType<typeof adjustmentsGetAdjustmentCount>>>
export type AdjustmentsGetAdjustmentCountQueryError = ErrorType<unknown>

export const useAdjustmentsGetAdjustmentCount = <TData = Awaited<ReturnType<typeof adjustmentsGetAdjustmentCount>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof adjustmentsGetAdjustmentCount>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdjustmentsGetAdjustmentCountQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof adjustmentsGetAdjustmentCount>>> = ({ signal }) => adjustmentsGetAdjustmentCount(signal);

  const query = useQuery<Awaited<ReturnType<typeof adjustmentsGetAdjustmentCount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const adjustmentsClearAdjustments = (
    
 ) => {
      return customInstance<void>(
      {url: `/Adjustments`, method: 'post'
    },
      );
    }
  


    export type AdjustmentsClearAdjustmentsMutationResult = NonNullable<Awaited<ReturnType<typeof adjustmentsClearAdjustments>>>
    
    export type AdjustmentsClearAdjustmentsMutationError = ErrorType<unknown>

    export const useAdjustmentsClearAdjustments = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof adjustmentsClearAdjustments>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof adjustmentsClearAdjustments>>, TVariables> = () => {
          

          return  adjustmentsClearAdjustments()
        }

      return useMutation<Awaited<ReturnType<typeof adjustmentsClearAdjustments>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const alphaCapturesUpload = (
    alphaCapturesUploadBody: BodyType<AlphaCapturesUploadBody>,
 ) => {const formData = new FormData();
if(alphaCapturesUploadBody.file !== undefined) {
 formData.append('file', alphaCapturesUploadBody.file)
 }

      return customInstance<void>(
      {url: `/AlphaCaptures/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


    export type AlphaCapturesUploadMutationResult = NonNullable<Awaited<ReturnType<typeof alphaCapturesUpload>>>
    export type AlphaCapturesUploadMutationBody = BodyType<AlphaCapturesUploadBody>
    export type AlphaCapturesUploadMutationError = ErrorType<unknown>

    export const useAlphaCapturesUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof alphaCapturesUpload>>, TError,{data: BodyType<AlphaCapturesUploadBody>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof alphaCapturesUpload>>, {data: BodyType<AlphaCapturesUploadBody>}> = (props) => {
          const {data} = props ?? {};

          return  alphaCapturesUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof alphaCapturesUpload>>, TError, {data: BodyType<AlphaCapturesUploadBody>}, TContext>(mutationFn, mutationOptions)
    }
    
export const employeeGroupsGetAll = (
    
 signal?: AbortSignal
) => {
      return customInstance<EmployeeGroupResponse[]>(
      {url: `/EmployeeGroups`, method: 'get', signal
    },
      );
    }
  

export const getEmployeeGroupsGetAllQueryKey = () => [`/EmployeeGroups`];

    
export type EmployeeGroupsGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof employeeGroupsGetAll>>>
export type EmployeeGroupsGetAllQueryError = ErrorType<unknown>

export const useEmployeeGroupsGetAll = <TData = Awaited<ReturnType<typeof employeeGroupsGetAll>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof employeeGroupsGetAll>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEmployeeGroupsGetAllQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof employeeGroupsGetAll>>> = ({ signal }) => employeeGroupsGetAll(signal);

  const query = useQuery<Awaited<ReturnType<typeof employeeGroupsGetAll>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const employeesGetEmployeeFilters = (
    params?: EmployeesGetEmployeeFiltersParams,
 signal?: AbortSignal
) => {
      return customInstance<EmployeeResponse[]>(
      {url: `/Employees/filters`, method: 'get',
        params, signal
    },
      );
    }
  

export const getEmployeesGetEmployeeFiltersQueryKey = (params?: EmployeesGetEmployeeFiltersParams,) => [`/Employees/filters`, ...(params ? [params]: [])];

    
export type EmployeesGetEmployeeFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof employeesGetEmployeeFilters>>>
export type EmployeesGetEmployeeFiltersQueryError = ErrorType<unknown>

export const useEmployeesGetEmployeeFilters = <TData = Awaited<ReturnType<typeof employeesGetEmployeeFilters>>, TError = ErrorType<unknown>>(
 params?: EmployeesGetEmployeeFiltersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof employeesGetEmployeeFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEmployeesGetEmployeeFiltersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof employeesGetEmployeeFilters>>> = ({ signal }) => employeesGetEmployeeFilters(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof employeesGetEmployeeFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const employeesGet = (
    params?: EmployeesGetParams,
 signal?: AbortSignal
) => {
      return customInstance<EmployeeResponsePaginatedResponse>(
      {url: `/Employees`, method: 'get',
        params, signal
    },
      );
    }
  

export const getEmployeesGetQueryKey = (params?: EmployeesGetParams,) => [`/Employees`, ...(params ? [params]: [])];

    
export type EmployeesGetQueryResult = NonNullable<Awaited<ReturnType<typeof employeesGet>>>
export type EmployeesGetQueryError = ErrorType<unknown>

export const useEmployeesGet = <TData = Awaited<ReturnType<typeof employeesGet>>, TError = ErrorType<unknown>>(
 params?: EmployeesGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof employeesGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEmployeesGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof employeesGet>>> = ({ signal }) => employeesGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof employeesGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const employeesUpdate = (
    employeeUpdateModel: BodyType<EmployeeUpdateModel>,
 ) => {
      return customInstance<EmployeeResponse>(
      {url: `/Employees`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: employeeUpdateModel
    },
      );
    }
  


    export type EmployeesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof employeesUpdate>>>
    export type EmployeesUpdateMutationBody = BodyType<EmployeeUpdateModel>
    export type EmployeesUpdateMutationError = ErrorType<unknown>

    export const useEmployeesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof employeesUpdate>>, TError,{data: BodyType<EmployeeUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof employeesUpdate>>, {data: BodyType<EmployeeUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  employeesUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof employeesUpdate>>, TError, {data: BodyType<EmployeeUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const employeesCreate = (
    employeeCreateModel: BodyType<EmployeeCreateModel>,
 ) => {
      return customInstance<EmployeeResponse>(
      {url: `/Employees`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: employeeCreateModel
    },
      );
    }
  


    export type EmployeesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof employeesCreate>>>
    export type EmployeesCreateMutationBody = BodyType<EmployeeCreateModel>
    export type EmployeesCreateMutationError = ErrorType<unknown>

    export const useEmployeesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof employeesCreate>>, TError,{data: BodyType<EmployeeCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof employeesCreate>>, {data: BodyType<EmployeeCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  employeesCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof employeesCreate>>, TError, {data: BodyType<EmployeeCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const employeesGetDetails = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<EmployeeResponse>(
      {url: `/Employees/${id}`, method: 'get', signal
    },
      );
    }
  

export const getEmployeesGetDetailsQueryKey = (id: number,) => [`/Employees/${id}`];

    
export type EmployeesGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof employeesGetDetails>>>
export type EmployeesGetDetailsQueryError = ErrorType<unknown>

export const useEmployeesGetDetails = <TData = Awaited<ReturnType<typeof employeesGetDetails>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof employeesGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEmployeesGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof employeesGetDetails>>> = ({ signal }) => employeesGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof employeesGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const employeesDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/Employees/${id}`, method: 'delete'
    },
      );
    }
  


    export type EmployeesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof employeesDelete>>>
    
    export type EmployeesDeleteMutationError = ErrorType<unknown>

    export const useEmployeesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof employeesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof employeesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  employeesDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof employeesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const employeesDownloadPhoneList = (
    
 signal?: AbortSignal
) => {
      return customInstance<DownloadResponse>(
      {url: `/Employees/downloadphonelist`, method: 'get', signal
    },
      );
    }
  

export const getEmployeesDownloadPhoneListQueryKey = () => [`/Employees/downloadphonelist`];

    
export type EmployeesDownloadPhoneListQueryResult = NonNullable<Awaited<ReturnType<typeof employeesDownloadPhoneList>>>
export type EmployeesDownloadPhoneListQueryError = ErrorType<unknown>

export const useEmployeesDownloadPhoneList = <TData = Awaited<ReturnType<typeof employeesDownloadPhoneList>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof employeesDownloadPhoneList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEmployeesDownloadPhoneListQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof employeesDownloadPhoneList>>> = ({ signal }) => employeesDownloadPhoneList(signal);

  const query = useQuery<Awaited<ReturnType<typeof employeesDownloadPhoneList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaActivitiesUpload = (
    fidessaActivitiesUploadBody: BodyType<FidessaActivitiesUploadBody>,
 ) => {const formData = new FormData();
if(fidessaActivitiesUploadBody.file !== undefined) {
 formData.append('file', fidessaActivitiesUploadBody.file)
 }

      return customInstance<void>(
      {url: `/FidessaActivities/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


    export type FidessaActivitiesUploadMutationResult = NonNullable<Awaited<ReturnType<typeof fidessaActivitiesUpload>>>
    export type FidessaActivitiesUploadMutationBody = BodyType<FidessaActivitiesUploadBody>
    export type FidessaActivitiesUploadMutationError = ErrorType<unknown>

    export const useFidessaActivitiesUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fidessaActivitiesUpload>>, TError,{data: BodyType<FidessaActivitiesUploadBody>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fidessaActivitiesUpload>>, {data: BodyType<FidessaActivitiesUploadBody>}> = (props) => {
          const {data} = props ?? {};

          return  fidessaActivitiesUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof fidessaActivitiesUpload>>, TError, {data: BodyType<FidessaActivitiesUploadBody>}, TContext>(mutationFn, mutationOptions)
    }
    
export const fidessaActivitiesGetFidessaActivityReport = (
    params?: FidessaActivitiesGetFidessaActivityReportParams,
 signal?: AbortSignal
) => {
      return customInstance<FidessaActivityResponsePaginatedResponse>(
      {url: `/FidessaActivities`, method: 'get',
        params, signal
    },
      );
    }
  

export const getFidessaActivitiesGetFidessaActivityReportQueryKey = (params?: FidessaActivitiesGetFidessaActivityReportParams,) => [`/FidessaActivities`, ...(params ? [params]: [])];

    
export type FidessaActivitiesGetFidessaActivityReportQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaActivitiesGetFidessaActivityReport>>>
export type FidessaActivitiesGetFidessaActivityReportQueryError = ErrorType<unknown>

export const useFidessaActivitiesGetFidessaActivityReport = <TData = Awaited<ReturnType<typeof fidessaActivitiesGetFidessaActivityReport>>, TError = ErrorType<unknown>>(
 params?: FidessaActivitiesGetFidessaActivityReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaActivitiesGetFidessaActivityReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaActivitiesGetFidessaActivityReportQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaActivitiesGetFidessaActivityReport>>> = ({ signal }) => fidessaActivitiesGetFidessaActivityReport(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaActivitiesGetFidessaActivityReport>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsDownload = (
    name: string,
    params?: FidessaReportsDownloadParams,
 signal?: AbortSignal
) => {
      return customInstance<DownloadResponse>(
      {url: `/FidessaReports/${name}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getFidessaReportsDownloadQueryKey = (name: string,
    params?: FidessaReportsDownloadParams,) => [`/FidessaReports/${name}`, ...(params ? [params]: [])];

    
export type FidessaReportsDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsDownload>>>
export type FidessaReportsDownloadQueryError = ErrorType<unknown>

export const useFidessaReportsDownload = <TData = Awaited<ReturnType<typeof fidessaReportsDownload>>, TError = ErrorType<unknown>>(
 name: string,
    params?: FidessaReportsDownloadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaReportsDownload>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaReportsDownloadQueryKey(name,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaReportsDownload>>> = ({ signal }) => fidessaReportsDownload(name,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaReportsDownload>>, TError, TData>(queryKey, queryFn, {enabled: !!(name), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsDownloadDates = (
    year: string,
    month: string,
    day: string,
    name: string,
    params?: FidessaReportsDownloadDatesParams,
 signal?: AbortSignal
) => {
      return customInstance<DownloadResponse>(
      {url: `/FidessaReports/${year}/${month}/${day}/${name}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getFidessaReportsDownloadDatesQueryKey = (year: string,
    month: string,
    day: string,
    name: string,
    params?: FidessaReportsDownloadDatesParams,) => [`/FidessaReports/${year}/${month}/${day}/${name}`, ...(params ? [params]: [])];

    
export type FidessaReportsDownloadDatesQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsDownloadDates>>>
export type FidessaReportsDownloadDatesQueryError = ErrorType<unknown>

export const useFidessaReportsDownloadDates = <TData = Awaited<ReturnType<typeof fidessaReportsDownloadDates>>, TError = ErrorType<unknown>>(
 year: string,
    month: string,
    day: string,
    name: string,
    params?: FidessaReportsDownloadDatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaReportsDownloadDates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaReportsDownloadDatesQueryKey(year,month,day,name,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaReportsDownloadDates>>> = ({ signal }) => fidessaReportsDownloadDates(year,month,day,name,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaReportsDownloadDates>>, TError, TData>(queryKey, queryFn, {enabled: !!(year && month && day && name), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsGetReportNames = (
    
 signal?: AbortSignal
) => {
      return customInstance<string[]>(
      {url: `/FidessaReports/report-names`, method: 'get', signal
    },
      );
    }
  

export const getFidessaReportsGetReportNamesQueryKey = () => [`/FidessaReports/report-names`];

    
export type FidessaReportsGetReportNamesQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsGetReportNames>>>
export type FidessaReportsGetReportNamesQueryError = ErrorType<unknown>

export const useFidessaReportsGetReportNames = <TData = Awaited<ReturnType<typeof fidessaReportsGetReportNames>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaReportsGetReportNames>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaReportsGetReportNamesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaReportsGetReportNames>>> = ({ signal }) => fidessaReportsGetReportNames(signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaReportsGetReportNames>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsGetMasterGroups = (
    
 signal?: AbortSignal
) => {
      return customInstance<string[]>(
      {url: `/FidessaReports/master-groups`, method: 'get', signal
    },
      );
    }
  

export const getFidessaReportsGetMasterGroupsQueryKey = () => [`/FidessaReports/master-groups`];

    
export type FidessaReportsGetMasterGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsGetMasterGroups>>>
export type FidessaReportsGetMasterGroupsQueryError = ErrorType<unknown>

export const useFidessaReportsGetMasterGroups = <TData = Awaited<ReturnType<typeof fidessaReportsGetMasterGroups>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaReportsGetMasterGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaReportsGetMasterGroupsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaReportsGetMasterGroups>>> = ({ signal }) => fidessaReportsGetMasterGroups(signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaReportsGetMasterGroups>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsGetReportGroups = (
    
 signal?: AbortSignal
) => {
      return customInstance<string[]>(
      {url: `/FidessaReports/report-groups`, method: 'get', signal
    },
      );
    }
  

export const getFidessaReportsGetReportGroupsQueryKey = () => [`/FidessaReports/report-groups`];

    
export type FidessaReportsGetReportGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsGetReportGroups>>>
export type FidessaReportsGetReportGroupsQueryError = ErrorType<unknown>

export const useFidessaReportsGetReportGroups = <TData = Awaited<ReturnType<typeof fidessaReportsGetReportGroups>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaReportsGetReportGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaReportsGetReportGroupsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaReportsGetReportGroups>>> = ({ signal }) => fidessaReportsGetReportGroups(signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaReportsGetReportGroups>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsGet = (
    params?: FidessaReportsGetParams,
 signal?: AbortSignal
) => {
      return customInstance<FidessaReportResponsePaginatedResponse>(
      {url: `/FidessaReports`, method: 'get',
        params, signal
    },
      );
    }
  

export const getFidessaReportsGetQueryKey = (params?: FidessaReportsGetParams,) => [`/FidessaReports`, ...(params ? [params]: [])];

    
export type FidessaReportsGetQueryResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsGet>>>
export type FidessaReportsGetQueryError = ErrorType<unknown>

export const useFidessaReportsGet = <TData = Awaited<ReturnType<typeof fidessaReportsGet>>, TError = ErrorType<unknown>>(
 params?: FidessaReportsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fidessaReportsGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFidessaReportsGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fidessaReportsGet>>> = ({ signal }) => fidessaReportsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof fidessaReportsGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const fidessaReportsUpsert = (
    fidessaReportId: string,
    fidessaReportUpsertRequest: BodyType<FidessaReportUpsertRequest>,
 ) => {
      return customInstance<FidessaReportResponse>(
      {url: `/FidessaReports/${fidessaReportId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: fidessaReportUpsertRequest
    },
      );
    }
  


    export type FidessaReportsUpsertMutationResult = NonNullable<Awaited<ReturnType<typeof fidessaReportsUpsert>>>
    export type FidessaReportsUpsertMutationBody = BodyType<FidessaReportUpsertRequest>
    export type FidessaReportsUpsertMutationError = ErrorType<unknown>

    export const useFidessaReportsUpsert = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fidessaReportsUpsert>>, TError,{fidessaReportId: string;data: BodyType<FidessaReportUpsertRequest>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fidessaReportsUpsert>>, {fidessaReportId: string;data: BodyType<FidessaReportUpsertRequest>}> = (props) => {
          const {fidessaReportId,data} = props ?? {};

          return  fidessaReportsUpsert(fidessaReportId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof fidessaReportsUpsert>>, TError, {fidessaReportId: string;data: BodyType<FidessaReportUpsertRequest>}, TContext>(mutationFn, mutationOptions)
    }
    
export const fidessaTransactionsUpload = (
    fidessaTransactionsUploadBody: BodyType<FidessaTransactionsUploadBody>,
 ) => {const formData = new FormData();
if(fidessaTransactionsUploadBody.file !== undefined) {
 formData.append('file', fidessaTransactionsUploadBody.file)
 }

      return customInstance<void>(
      {url: `/FidessaTransactions/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


    export type FidessaTransactionsUploadMutationResult = NonNullable<Awaited<ReturnType<typeof fidessaTransactionsUpload>>>
    export type FidessaTransactionsUploadMutationBody = BodyType<FidessaTransactionsUploadBody>
    export type FidessaTransactionsUploadMutationError = ErrorType<unknown>

    export const useFidessaTransactionsUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof fidessaTransactionsUpload>>, TError,{data: BodyType<FidessaTransactionsUploadBody>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof fidessaTransactionsUpload>>, {data: BodyType<FidessaTransactionsUploadBody>}> = (props) => {
          const {data} = props ?? {};

          return  fidessaTransactionsUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof fidessaTransactionsUpload>>, TError, {data: BodyType<FidessaTransactionsUploadBody>}, TContext>(mutationFn, mutationOptions)
    }
    
export const importStatusGetActivitiesImportStatus = (
    
 signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/ImportStatus/activities`, method: 'get', signal
    },
      );
    }
  

export const getImportStatusGetActivitiesImportStatusQueryKey = () => [`/ImportStatus/activities`];

    
export type ImportStatusGetActivitiesImportStatusQueryResult = NonNullable<Awaited<ReturnType<typeof importStatusGetActivitiesImportStatus>>>
export type ImportStatusGetActivitiesImportStatusQueryError = ErrorType<unknown>

export const useImportStatusGetActivitiesImportStatus = <TData = Awaited<ReturnType<typeof importStatusGetActivitiesImportStatus>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof importStatusGetActivitiesImportStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getImportStatusGetActivitiesImportStatusQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof importStatusGetActivitiesImportStatus>>> = ({ signal }) => importStatusGetActivitiesImportStatus(signal);

  const query = useQuery<Awaited<ReturnType<typeof importStatusGetActivitiesImportStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const importStatusGetAlphaCapturesImportStatus = (
    
 signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/ImportStatus/alpha-captures`, method: 'get', signal
    },
      );
    }
  

export const getImportStatusGetAlphaCapturesImportStatusQueryKey = () => [`/ImportStatus/alpha-captures`];

    
export type ImportStatusGetAlphaCapturesImportStatusQueryResult = NonNullable<Awaited<ReturnType<typeof importStatusGetAlphaCapturesImportStatus>>>
export type ImportStatusGetAlphaCapturesImportStatusQueryError = ErrorType<unknown>

export const useImportStatusGetAlphaCapturesImportStatus = <TData = Awaited<ReturnType<typeof importStatusGetAlphaCapturesImportStatus>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof importStatusGetAlphaCapturesImportStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getImportStatusGetAlphaCapturesImportStatusQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof importStatusGetAlphaCapturesImportStatus>>> = ({ signal }) => importStatusGetAlphaCapturesImportStatus(signal);

  const query = useQuery<Awaited<ReturnType<typeof importStatusGetAlphaCapturesImportStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const importStatusGetFidessaActivityImportStatus = (
    
 signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/ImportStatus/fidessa-activity`, method: 'get', signal
    },
      );
    }
  

export const getImportStatusGetFidessaActivityImportStatusQueryKey = () => [`/ImportStatus/fidessa-activity`];

    
export type ImportStatusGetFidessaActivityImportStatusQueryResult = NonNullable<Awaited<ReturnType<typeof importStatusGetFidessaActivityImportStatus>>>
export type ImportStatusGetFidessaActivityImportStatusQueryError = ErrorType<unknown>

export const useImportStatusGetFidessaActivityImportStatus = <TData = Awaited<ReturnType<typeof importStatusGetFidessaActivityImportStatus>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof importStatusGetFidessaActivityImportStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getImportStatusGetFidessaActivityImportStatusQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof importStatusGetFidessaActivityImportStatus>>> = ({ signal }) => importStatusGetFidessaActivityImportStatus(signal);

  const query = useQuery<Awaited<ReturnType<typeof importStatusGetFidessaActivityImportStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const importStatusGetFidessaTransactionsImportStatus = (
    
 signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/ImportStatus/fidessa-transactions`, method: 'get', signal
    },
      );
    }
  

export const getImportStatusGetFidessaTransactionsImportStatusQueryKey = () => [`/ImportStatus/fidessa-transactions`];

    
export type ImportStatusGetFidessaTransactionsImportStatusQueryResult = NonNullable<Awaited<ReturnType<typeof importStatusGetFidessaTransactionsImportStatus>>>
export type ImportStatusGetFidessaTransactionsImportStatusQueryError = ErrorType<unknown>

export const useImportStatusGetFidessaTransactionsImportStatus = <TData = Awaited<ReturnType<typeof importStatusGetFidessaTransactionsImportStatus>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof importStatusGetFidessaTransactionsImportStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getImportStatusGetFidessaTransactionsImportStatusQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof importStatusGetFidessaTransactionsImportStatus>>> = ({ signal }) => importStatusGetFidessaTransactionsImportStatus(signal);

  const query = useQuery<Awaited<ReturnType<typeof importStatusGetFidessaTransactionsImportStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const importStatusGetSchwabTransactionsImportStatus = (
    
 signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/ImportStatus/schwab-transactions`, method: 'get', signal
    },
      );
    }
  

export const getImportStatusGetSchwabTransactionsImportStatusQueryKey = () => [`/ImportStatus/schwab-transactions`];

    
export type ImportStatusGetSchwabTransactionsImportStatusQueryResult = NonNullable<Awaited<ReturnType<typeof importStatusGetSchwabTransactionsImportStatus>>>
export type ImportStatusGetSchwabTransactionsImportStatusQueryError = ErrorType<unknown>

export const useImportStatusGetSchwabTransactionsImportStatus = <TData = Awaited<ReturnType<typeof importStatusGetSchwabTransactionsImportStatus>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof importStatusGetSchwabTransactionsImportStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getImportStatusGetSchwabTransactionsImportStatusQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof importStatusGetSchwabTransactionsImportStatus>>> = ({ signal }) => importStatusGetSchwabTransactionsImportStatus(signal);

  const query = useQuery<Awaited<ReturnType<typeof importStatusGetSchwabTransactionsImportStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const janusExceptionsGet = (
    params?: JanusExceptionsGetParams,
 signal?: AbortSignal
) => {
      return customInstance<JanusExceptionResponsePaginatedResponse>(
      {url: `/JanusExceptions`, method: 'get',
        params, signal
    },
      );
    }
  

export const getJanusExceptionsGetQueryKey = (params?: JanusExceptionsGetParams,) => [`/JanusExceptions`, ...(params ? [params]: [])];

    
export type JanusExceptionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof janusExceptionsGet>>>
export type JanusExceptionsGetQueryError = ErrorType<unknown>

export const useJanusExceptionsGet = <TData = Awaited<ReturnType<typeof janusExceptionsGet>>, TError = ErrorType<unknown>>(
 params?: JanusExceptionsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof janusExceptionsGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getJanusExceptionsGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof janusExceptionsGet>>> = ({ signal }) => janusExceptionsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof janusExceptionsGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const janusExceptionsUpdate = (
    janusExceptionUpdateModel: BodyType<JanusExceptionUpdateModel>,
 ) => {
      return customInstance<JanusExceptionResponse>(
      {url: `/JanusExceptions`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: janusExceptionUpdateModel
    },
      );
    }
  


    export type JanusExceptionsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof janusExceptionsUpdate>>>
    export type JanusExceptionsUpdateMutationBody = BodyType<JanusExceptionUpdateModel>
    export type JanusExceptionsUpdateMutationError = ErrorType<unknown>

    export const useJanusExceptionsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof janusExceptionsUpdate>>, TError,{data: BodyType<JanusExceptionUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof janusExceptionsUpdate>>, {data: BodyType<JanusExceptionUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  janusExceptionsUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof janusExceptionsUpdate>>, TError, {data: BodyType<JanusExceptionUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const janusExceptionsCreate = (
    janusExceptionCreateModel: BodyType<JanusExceptionCreateModel>,
 ) => {
      return customInstance<JanusExceptionResponse>(
      {url: `/JanusExceptions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: janusExceptionCreateModel
    },
      );
    }
  


    export type JanusExceptionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof janusExceptionsCreate>>>
    export type JanusExceptionsCreateMutationBody = BodyType<JanusExceptionCreateModel>
    export type JanusExceptionsCreateMutationError = ErrorType<unknown>

    export const useJanusExceptionsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof janusExceptionsCreate>>, TError,{data: BodyType<JanusExceptionCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof janusExceptionsCreate>>, {data: BodyType<JanusExceptionCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  janusExceptionsCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof janusExceptionsCreate>>, TError, {data: BodyType<JanusExceptionCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const janusExceptionsDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/JanusExceptions/${id}`, method: 'delete'
    },
      );
    }
  


    export type JanusExceptionsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof janusExceptionsDelete>>>
    
    export type JanusExceptionsDeleteMutationError = ErrorType<unknown>

    export const useJanusExceptionsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof janusExceptionsDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof janusExceptionsDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  janusExceptionsDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof janusExceptionsDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const managedReportsGet = (
    params?: ManagedReportsGetParams,
 signal?: AbortSignal
) => {
      return customInstance<ManagedReportResponsePaginatedResponse>(
      {url: `/ManagedReports`, method: 'get',
        params, signal
    },
      );
    }
  

export const getManagedReportsGetQueryKey = (params?: ManagedReportsGetParams,) => [`/ManagedReports`, ...(params ? [params]: [])];

    
export type ManagedReportsGetQueryResult = NonNullable<Awaited<ReturnType<typeof managedReportsGet>>>
export type ManagedReportsGetQueryError = ErrorType<unknown>

export const useManagedReportsGet = <TData = Awaited<ReturnType<typeof managedReportsGet>>, TError = ErrorType<unknown>>(
 params?: ManagedReportsGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof managedReportsGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getManagedReportsGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof managedReportsGet>>> = ({ signal }) => managedReportsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof managedReportsGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const managedReportsCreate = (
    managedReportCreateRequest: BodyType<ManagedReportCreateRequest>,
 ) => {
      return customInstance<ManagedReportResponse>(
      {url: `/ManagedReports`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: managedReportCreateRequest
    },
      );
    }
  


    export type ManagedReportsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof managedReportsCreate>>>
    export type ManagedReportsCreateMutationBody = BodyType<ManagedReportCreateRequest>
    export type ManagedReportsCreateMutationError = ErrorType<unknown>

    export const useManagedReportsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof managedReportsCreate>>, TError,{data: BodyType<ManagedReportCreateRequest>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof managedReportsCreate>>, {data: BodyType<ManagedReportCreateRequest>}> = (props) => {
          const {data} = props ?? {};

          return  managedReportsCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof managedReportsCreate>>, TError, {data: BodyType<ManagedReportCreateRequest>}, TContext>(mutationFn, mutationOptions)
    }
    
export const managedReportsUpdate = (
    id: number,
    managedReportUpdateRequest: BodyType<ManagedReportUpdateRequest>,
 ) => {
      return customInstance<ManagedReportResponse>(
      {url: `/ManagedReports/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: managedReportUpdateRequest
    },
      );
    }
  


    export type ManagedReportsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof managedReportsUpdate>>>
    export type ManagedReportsUpdateMutationBody = BodyType<ManagedReportUpdateRequest>
    export type ManagedReportsUpdateMutationError = ErrorType<unknown>

    export const useManagedReportsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof managedReportsUpdate>>, TError,{id: number;data: BodyType<ManagedReportUpdateRequest>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof managedReportsUpdate>>, {id: number;data: BodyType<ManagedReportUpdateRequest>}> = (props) => {
          const {id,data} = props ?? {};

          return  managedReportsUpdate(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof managedReportsUpdate>>, TError, {id: number;data: BodyType<ManagedReportUpdateRequest>}, TContext>(mutationFn, mutationOptions)
    }
    
export const managedReportsDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/ManagedReports/${id}`, method: 'delete'
    },
      );
    }
  


    export type ManagedReportsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof managedReportsDelete>>>
    
    export type ManagedReportsDeleteMutationError = ErrorType<unknown>

    export const useManagedReportsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof managedReportsDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof managedReportsDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  managedReportsDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof managedReportsDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerGetMoneyManagerFilters = (
    
 signal?: AbortSignal
) => {
      return customInstance<MoneyManagerBaseResponse[]>(
      {url: `/MoneyManager/filters`, method: 'get', signal
    },
      );
    }
  

export const getMoneyManagerGetMoneyManagerFiltersQueryKey = () => [`/MoneyManager/filters`];

    
export type MoneyManagerGetMoneyManagerFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerFilters>>>
export type MoneyManagerGetMoneyManagerFiltersQueryError = ErrorType<unknown>

export const useMoneyManagerGetMoneyManagerFilters = <TData = Awaited<ReturnType<typeof moneyManagerGetMoneyManagerFilters>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetMoneyManagerFiltersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerFilters>>> = ({ signal }) => moneyManagerGetMoneyManagerFilters(signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerGet = (
    params?: MoneyManagerGetParams,
 signal?: AbortSignal
) => {
      return customInstance<MoneyManagerResponsePaginatedResponse>(
      {url: `/MoneyManager`, method: 'get',
        params, signal
    },
      );
    }
  

export const getMoneyManagerGetQueryKey = (params?: MoneyManagerGetParams,) => [`/MoneyManager`, ...(params ? [params]: [])];

    
export type MoneyManagerGetQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGet>>>
export type MoneyManagerGetQueryError = ErrorType<unknown>

export const useMoneyManagerGet = <TData = Awaited<ReturnType<typeof moneyManagerGet>>, TError = ErrorType<unknown>>(
 params?: MoneyManagerGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGet>>> = ({ signal }) => moneyManagerGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerUpdate = (
    moneyManagerUpdateModel: BodyType<MoneyManagerUpdateModel>,
 ) => {
      return customInstance<MoneyManagerResponse>(
      {url: `/MoneyManager`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: moneyManagerUpdateModel
    },
      );
    }
  


    export type MoneyManagerUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof moneyManagerUpdate>>>
    export type MoneyManagerUpdateMutationBody = BodyType<MoneyManagerUpdateModel>
    export type MoneyManagerUpdateMutationError = ErrorType<unknown>

    export const useMoneyManagerUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moneyManagerUpdate>>, TError,{data: BodyType<MoneyManagerUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moneyManagerUpdate>>, {data: BodyType<MoneyManagerUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  moneyManagerUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof moneyManagerUpdate>>, TError, {data: BodyType<MoneyManagerUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerCreate = (
    moneyManagerCreateModel: BodyType<MoneyManagerCreateModel>,
 ) => {
      return customInstance<MoneyManagerResponse>(
      {url: `/MoneyManager`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: moneyManagerCreateModel
    },
      );
    }
  


    export type MoneyManagerCreateMutationResult = NonNullable<Awaited<ReturnType<typeof moneyManagerCreate>>>
    export type MoneyManagerCreateMutationBody = BodyType<MoneyManagerCreateModel>
    export type MoneyManagerCreateMutationError = ErrorType<unknown>

    export const useMoneyManagerCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moneyManagerCreate>>, TError,{data: BodyType<MoneyManagerCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moneyManagerCreate>>, {data: BodyType<MoneyManagerCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  moneyManagerCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof moneyManagerCreate>>, TError, {data: BodyType<MoneyManagerCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerGetDetails = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<MoneyManagerResponse>(
      {url: `/MoneyManager/${id}`, method: 'get', signal
    },
      );
    }
  

export const getMoneyManagerGetDetailsQueryKey = (id: string,) => [`/MoneyManager/${id}`];

    
export type MoneyManagerGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetDetails>>>
export type MoneyManagerGetDetailsQueryError = ErrorType<unknown>

export const useMoneyManagerGetDetails = <TData = Awaited<ReturnType<typeof moneyManagerGetDetails>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetDetails>>> = ({ signal }) => moneyManagerGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerDelete = (
    id: string,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/MoneyManager/${id}`, method: 'delete'
    },
      );
    }
  


    export type MoneyManagerDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof moneyManagerDelete>>>
    
    export type MoneyManagerDeleteMutationError = ErrorType<unknown>

    export const useMoneyManagerDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moneyManagerDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moneyManagerDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  moneyManagerDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof moneyManagerDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerGetCoverage = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<PayoutResponse[]>(
      {url: `/MoneyManager/${id}/coverage`, method: 'get', signal
    },
      );
    }
  

export const getMoneyManagerGetCoverageQueryKey = (id: string,) => [`/MoneyManager/${id}/coverage`];

    
export type MoneyManagerGetCoverageQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetCoverage>>>
export type MoneyManagerGetCoverageQueryError = ErrorType<unknown>

export const useMoneyManagerGetCoverage = <TData = Awaited<ReturnType<typeof moneyManagerGetCoverage>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetCoverage>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetCoverageQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetCoverage>>> = ({ signal }) => moneyManagerGetCoverage(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetCoverage>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerGetHistory = (
    id: string,
    params?: MoneyManagerGetHistoryParams,
 signal?: AbortSignal
) => {
      return customInstance<MoneyManagerGetHistoryResponse>(
      {url: `/MoneyManager/${id}/history`, method: 'get',
        params, signal
    },
      );
    }
  

export const getMoneyManagerGetHistoryQueryKey = (id: string,
    params?: MoneyManagerGetHistoryParams,) => [`/MoneyManager/${id}/history`, ...(params ? [params]: [])];

    
export type MoneyManagerGetHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetHistory>>>
export type MoneyManagerGetHistoryQueryError = ErrorType<unknown>

export const useMoneyManagerGetHistory = <TData = Awaited<ReturnType<typeof moneyManagerGetHistory>>, TError = ErrorType<unknown>>(
 id: string,
    params?: MoneyManagerGetHistoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetHistory>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetHistoryQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetHistory>>> = ({ signal }) => moneyManagerGetHistory(id,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetHistory>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerGetSettlements = (
    mmid: string,
 signal?: AbortSignal
) => {
      return customInstance<SettlementContactResponse[]>(
      {url: `/MoneyManager/${mmid}/settlements`, method: 'get', signal
    },
      );
    }
  

export const getMoneyManagerGetSettlementsQueryKey = (mmid: string,) => [`/MoneyManager/${mmid}/settlements`];

    
export type MoneyManagerGetSettlementsQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetSettlements>>>
export type MoneyManagerGetSettlementsQueryError = ErrorType<unknown>

export const useMoneyManagerGetSettlements = <TData = Awaited<ReturnType<typeof moneyManagerGetSettlements>>, TError = ErrorType<unknown>>(
 mmid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetSettlements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetSettlementsQueryKey(mmid);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetSettlements>>> = ({ signal }) => moneyManagerGetSettlements(mmid, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetSettlements>>, TError, TData>(queryKey, queryFn, {enabled: !!(mmid), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerCreateSettlement = (
    mmid: string,
    settlementContactRequest: BodyType<SettlementContactRequest>,
 ) => {
      return customInstance<SettlementContactResponse>(
      {url: `/MoneyManager/${mmid}/settlements`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: settlementContactRequest
    },
      );
    }
  


    export type MoneyManagerCreateSettlementMutationResult = NonNullable<Awaited<ReturnType<typeof moneyManagerCreateSettlement>>>
    export type MoneyManagerCreateSettlementMutationBody = BodyType<SettlementContactRequest>
    export type MoneyManagerCreateSettlementMutationError = ErrorType<unknown>

    export const useMoneyManagerCreateSettlement = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moneyManagerCreateSettlement>>, TError,{mmid: string;data: BodyType<SettlementContactRequest>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moneyManagerCreateSettlement>>, {mmid: string;data: BodyType<SettlementContactRequest>}> = (props) => {
          const {mmid,data} = props ?? {};

          return  moneyManagerCreateSettlement(mmid,data,)
        }

      return useMutation<Awaited<ReturnType<typeof moneyManagerCreateSettlement>>, TError, {mmid: string;data: BodyType<SettlementContactRequest>}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerDeleteSettlement = (
    mmid: string,
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/MoneyManager/${mmid}/settlements/${id}`, method: 'delete'
    },
      );
    }
  


    export type MoneyManagerDeleteSettlementMutationResult = NonNullable<Awaited<ReturnType<typeof moneyManagerDeleteSettlement>>>
    
    export type MoneyManagerDeleteSettlementMutationError = ErrorType<unknown>

    export const useMoneyManagerDeleteSettlement = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moneyManagerDeleteSettlement>>, TError,{mmid: string;id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moneyManagerDeleteSettlement>>, {mmid: string;id: number}> = (props) => {
          const {mmid,id} = props ?? {};

          return  moneyManagerDeleteSettlement(mmid,id,)
        }

      return useMutation<Awaited<ReturnType<typeof moneyManagerDeleteSettlement>>, TError, {mmid: string;id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerUpsertSettlement = (
    mmid: string,
    id: number,
    settlementContactRequest: BodyType<SettlementContactRequest>,
 ) => {
      return customInstance<SettlementContactResponse>(
      {url: `/MoneyManager/${mmid}/settlements/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: settlementContactRequest
    },
      );
    }
  


    export type MoneyManagerUpsertSettlementMutationResult = NonNullable<Awaited<ReturnType<typeof moneyManagerUpsertSettlement>>>
    export type MoneyManagerUpsertSettlementMutationBody = BodyType<SettlementContactRequest>
    export type MoneyManagerUpsertSettlementMutationError = ErrorType<unknown>

    export const useMoneyManagerUpsertSettlement = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof moneyManagerUpsertSettlement>>, TError,{mmid: string;id: number;data: BodyType<SettlementContactRequest>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof moneyManagerUpsertSettlement>>, {mmid: string;id: number;data: BodyType<SettlementContactRequest>}> = (props) => {
          const {mmid,id,data} = props ?? {};

          return  moneyManagerUpsertSettlement(mmid,id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof moneyManagerUpsertSettlement>>, TError, {mmid: string;id: number;data: BodyType<SettlementContactRequest>}, TContext>(mutationFn, mutationOptions)
    }
    
export const moneyManagerGetMoneyManagerHistory = (
    params?: MoneyManagerGetMoneyManagerHistoryParams,
 signal?: AbortSignal
) => {
      return customInstance<HistoryResponsePaginatedResponse>(
      {url: `/MoneyManager/history`, method: 'get',
        params, signal
    },
      );
    }
  

export const getMoneyManagerGetMoneyManagerHistoryQueryKey = (params?: MoneyManagerGetMoneyManagerHistoryParams,) => [`/MoneyManager/history`, ...(params ? [params]: [])];

    
export type MoneyManagerGetMoneyManagerHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerHistory>>>
export type MoneyManagerGetMoneyManagerHistoryQueryError = ErrorType<unknown>

export const useMoneyManagerGetMoneyManagerHistory = <TData = Awaited<ReturnType<typeof moneyManagerGetMoneyManagerHistory>>, TError = ErrorType<unknown>>(
 params?: MoneyManagerGetMoneyManagerHistoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerHistory>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetMoneyManagerHistoryQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerHistory>>> = ({ signal }) => moneyManagerGetMoneyManagerHistory(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetMoneyManagerHistory>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const moneyManagerGetNewMmid = (
    params?: MoneyManagerGetNewMmidParams,
 signal?: AbortSignal
) => {
      return customInstance<NewMmidResponsePaginatedResponse>(
      {url: `/MoneyManager/listnew`, method: 'get',
        params, signal
    },
      );
    }
  

export const getMoneyManagerGetNewMmidQueryKey = (params?: MoneyManagerGetNewMmidParams,) => [`/MoneyManager/listnew`, ...(params ? [params]: [])];

    
export type MoneyManagerGetNewMmidQueryResult = NonNullable<Awaited<ReturnType<typeof moneyManagerGetNewMmid>>>
export type MoneyManagerGetNewMmidQueryError = ErrorType<unknown>

export const useMoneyManagerGetNewMmid = <TData = Awaited<ReturnType<typeof moneyManagerGetNewMmid>>, TError = ErrorType<unknown>>(
 params?: MoneyManagerGetNewMmidParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof moneyManagerGetNewMmid>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMoneyManagerGetNewMmidQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof moneyManagerGetNewMmid>>> = ({ signal }) => moneyManagerGetNewMmid(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof moneyManagerGetNewMmid>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const payoutGet = (
    params?: PayoutGetParams,
 signal?: AbortSignal
) => {
      return customInstance<PayoutResponsePaginatedResponse>(
      {url: `/Payout`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPayoutGetQueryKey = (params?: PayoutGetParams,) => [`/Payout`, ...(params ? [params]: [])];

    
export type PayoutGetQueryResult = NonNullable<Awaited<ReturnType<typeof payoutGet>>>
export type PayoutGetQueryError = ErrorType<unknown>

export const usePayoutGet = <TData = Awaited<ReturnType<typeof payoutGet>>, TError = ErrorType<unknown>>(
 params?: PayoutGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof payoutGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPayoutGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof payoutGet>>> = ({ signal }) => payoutGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof payoutGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const payoutUpdate = (
    payoutUpdateModel: BodyType<PayoutUpdateModel>,
 ) => {
      return customInstance<PayoutResponse>(
      {url: `/Payout`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: payoutUpdateModel
    },
      );
    }
  


    export type PayoutUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof payoutUpdate>>>
    export type PayoutUpdateMutationBody = BodyType<PayoutUpdateModel>
    export type PayoutUpdateMutationError = ErrorType<unknown>

    export const usePayoutUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof payoutUpdate>>, TError,{data: BodyType<PayoutUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof payoutUpdate>>, {data: BodyType<PayoutUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  payoutUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof payoutUpdate>>, TError, {data: BodyType<PayoutUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const payoutCreate = (
    payoutCreateModel: BodyType<PayoutCreateModel>,
 ) => {
      return customInstance<PayoutResponse>(
      {url: `/Payout`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: payoutCreateModel
    },
      );
    }
  


    export type PayoutCreateMutationResult = NonNullable<Awaited<ReturnType<typeof payoutCreate>>>
    export type PayoutCreateMutationBody = BodyType<PayoutCreateModel>
    export type PayoutCreateMutationError = ErrorType<unknown>

    export const usePayoutCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof payoutCreate>>, TError,{data: BodyType<PayoutCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof payoutCreate>>, {data: BodyType<PayoutCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  payoutCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof payoutCreate>>, TError, {data: BodyType<PayoutCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const payoutGetDetails = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<PayoutResponse>(
      {url: `/Payout/${id}`, method: 'get', signal
    },
      );
    }
  

export const getPayoutGetDetailsQueryKey = (id: number,) => [`/Payout/${id}`];

    
export type PayoutGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof payoutGetDetails>>>
export type PayoutGetDetailsQueryError = ErrorType<unknown>

export const usePayoutGetDetails = <TData = Awaited<ReturnType<typeof payoutGetDetails>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof payoutGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPayoutGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof payoutGetDetails>>> = ({ signal }) => payoutGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof payoutGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const payoutDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/Payout/${id}`, method: 'delete'
    },
      );
    }
  


    export type PayoutDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof payoutDelete>>>
    
    export type PayoutDeleteMutationError = ErrorType<unknown>

    export const usePayoutDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof payoutDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof payoutDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  payoutDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof payoutDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const previewInformationMoneyManagerDetailActivity = (
    params?: PreviewInformationMoneyManagerDetailActivityParams,
 signal?: AbortSignal
) => {
      return customInstance<MmidDetailActivityPaginatedResponse>(
      {url: `/PreviewInformation/mmdetail`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPreviewInformationMoneyManagerDetailActivityQueryKey = (params?: PreviewInformationMoneyManagerDetailActivityParams,) => [`/PreviewInformation/mmdetail`, ...(params ? [params]: [])];

    
export type PreviewInformationMoneyManagerDetailActivityQueryResult = NonNullable<Awaited<ReturnType<typeof previewInformationMoneyManagerDetailActivity>>>
export type PreviewInformationMoneyManagerDetailActivityQueryError = ErrorType<unknown>

export const usePreviewInformationMoneyManagerDetailActivity = <TData = Awaited<ReturnType<typeof previewInformationMoneyManagerDetailActivity>>, TError = ErrorType<unknown>>(
 params?: PreviewInformationMoneyManagerDetailActivityParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof previewInformationMoneyManagerDetailActivity>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPreviewInformationMoneyManagerDetailActivityQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof previewInformationMoneyManagerDetailActivity>>> = ({ signal }) => previewInformationMoneyManagerDetailActivity(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof previewInformationMoneyManagerDetailActivity>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const previewInformationWiresAndChecks = (
    params?: PreviewInformationWiresAndChecksParams,
 signal?: AbortSignal
) => {
      return customInstance<WireAndChecksResultPaginatedResponse>(
      {url: `/PreviewInformation/wireandchecks`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPreviewInformationWiresAndChecksQueryKey = (params?: PreviewInformationWiresAndChecksParams,) => [`/PreviewInformation/wireandchecks`, ...(params ? [params]: [])];

    
export type PreviewInformationWiresAndChecksQueryResult = NonNullable<Awaited<ReturnType<typeof previewInformationWiresAndChecks>>>
export type PreviewInformationWiresAndChecksQueryError = ErrorType<unknown>

export const usePreviewInformationWiresAndChecks = <TData = Awaited<ReturnType<typeof previewInformationWiresAndChecks>>, TError = ErrorType<unknown>>(
 params?: PreviewInformationWiresAndChecksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof previewInformationWiresAndChecks>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPreviewInformationWiresAndChecksQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof previewInformationWiresAndChecks>>> = ({ signal }) => previewInformationWiresAndChecks(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof previewInformationWiresAndChecks>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const previewInformationLtid = (
    params?: PreviewInformationLtidParams,
 signal?: AbortSignal
) => {
      return customInstance<LtidResultPaginatedResponse>(
      {url: `/PreviewInformation/ltid`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPreviewInformationLtidQueryKey = (params?: PreviewInformationLtidParams,) => [`/PreviewInformation/ltid`, ...(params ? [params]: [])];

    
export type PreviewInformationLtidQueryResult = NonNullable<Awaited<ReturnType<typeof previewInformationLtid>>>
export type PreviewInformationLtidQueryError = ErrorType<unknown>

export const usePreviewInformationLtid = <TData = Awaited<ReturnType<typeof previewInformationLtid>>, TError = ErrorType<unknown>>(
 params?: PreviewInformationLtidParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof previewInformationLtid>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPreviewInformationLtidQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof previewInformationLtid>>> = ({ signal }) => previewInformationLtid(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof previewInformationLtid>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const previewInformationRawActivity = (
    params?: PreviewInformationRawActivityParams,
 signal?: AbortSignal
) => {
      return customInstance<ActivityResultPaginatedResponse>(
      {url: `/PreviewInformation/raw-activity`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPreviewInformationRawActivityQueryKey = (params?: PreviewInformationRawActivityParams,) => [`/PreviewInformation/raw-activity`, ...(params ? [params]: [])];

    
export type PreviewInformationRawActivityQueryResult = NonNullable<Awaited<ReturnType<typeof previewInformationRawActivity>>>
export type PreviewInformationRawActivityQueryError = ErrorType<unknown>

export const usePreviewInformationRawActivity = <TData = Awaited<ReturnType<typeof previewInformationRawActivity>>, TError = ErrorType<unknown>>(
 params?: PreviewInformationRawActivityParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof previewInformationRawActivity>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPreviewInformationRawActivityQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof previewInformationRawActivity>>> = ({ signal }) => previewInformationRawActivity(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof previewInformationRawActivity>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const previewInformationGetActivityRecap = (
    params?: PreviewInformationGetActivityRecapParams,
 signal?: AbortSignal
) => {
      return customInstance<ActivityRecapResponsePaginatedResponse>(
      {url: `/PreviewInformation/activity-recap`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPreviewInformationGetActivityRecapQueryKey = (params?: PreviewInformationGetActivityRecapParams,) => [`/PreviewInformation/activity-recap`, ...(params ? [params]: [])];

    
export type PreviewInformationGetActivityRecapQueryResult = NonNullable<Awaited<ReturnType<typeof previewInformationGetActivityRecap>>>
export type PreviewInformationGetActivityRecapQueryError = ErrorType<unknown>

export const usePreviewInformationGetActivityRecap = <TData = Awaited<ReturnType<typeof previewInformationGetActivityRecap>>, TError = ErrorType<unknown>>(
 params?: PreviewInformationGetActivityRecapParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof previewInformationGetActivityRecap>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPreviewInformationGetActivityRecapQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof previewInformationGetActivityRecap>>> = ({ signal }) => previewInformationGetActivityRecap(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof previewInformationGetActivityRecap>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const previewInformationDownloadActivityRecap = (
    params?: PreviewInformationDownloadActivityRecapParams,
 signal?: AbortSignal
) => {
      return customInstance<DownloadResponse>(
      {url: `/PreviewInformation/activity-recap/download`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPreviewInformationDownloadActivityRecapQueryKey = (params?: PreviewInformationDownloadActivityRecapParams,) => [`/PreviewInformation/activity-recap/download`, ...(params ? [params]: [])];

    
export type PreviewInformationDownloadActivityRecapQueryResult = NonNullable<Awaited<ReturnType<typeof previewInformationDownloadActivityRecap>>>
export type PreviewInformationDownloadActivityRecapQueryError = ErrorType<unknown>

export const usePreviewInformationDownloadActivityRecap = <TData = Awaited<ReturnType<typeof previewInformationDownloadActivityRecap>>, TError = ErrorType<unknown>>(
 params?: PreviewInformationDownloadActivityRecapParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof previewInformationDownloadActivityRecap>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPreviewInformationDownloadActivityRecapQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof previewInformationDownloadActivityRecap>>> = ({ signal }) => previewInformationDownloadActivityRecap(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof previewInformationDownloadActivityRecap>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const producersGetProducerFilters = (
    params?: ProducersGetProducerFiltersParams,
 signal?: AbortSignal
) => {
      return customInstance<ProducerResponse[]>(
      {url: `/Producers/filters`, method: 'get',
        params, signal
    },
      );
    }
  

export const getProducersGetProducerFiltersQueryKey = (params?: ProducersGetProducerFiltersParams,) => [`/Producers/filters`, ...(params ? [params]: [])];

    
export type ProducersGetProducerFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof producersGetProducerFilters>>>
export type ProducersGetProducerFiltersQueryError = ErrorType<unknown>

export const useProducersGetProducerFilters = <TData = Awaited<ReturnType<typeof producersGetProducerFilters>>, TError = ErrorType<unknown>>(
 params?: ProducersGetProducerFiltersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof producersGetProducerFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProducersGetProducerFiltersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof producersGetProducerFilters>>> = ({ signal }) => producersGetProducerFilters(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof producersGetProducerFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const producersGet = (
    params?: ProducersGetParams,
 signal?: AbortSignal
) => {
      return customInstance<ProducerResponsePaginatedResponse>(
      {url: `/Producers`, method: 'get',
        params, signal
    },
      );
    }
  

export const getProducersGetQueryKey = (params?: ProducersGetParams,) => [`/Producers`, ...(params ? [params]: [])];

    
export type ProducersGetQueryResult = NonNullable<Awaited<ReturnType<typeof producersGet>>>
export type ProducersGetQueryError = ErrorType<unknown>

export const useProducersGet = <TData = Awaited<ReturnType<typeof producersGet>>, TError = ErrorType<unknown>>(
 params?: ProducersGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof producersGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProducersGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof producersGet>>> = ({ signal }) => producersGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof producersGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const producersUpdate = (
    producerUpdateModel: BodyType<ProducerUpdateModel>,
 ) => {
      return customInstance<ProducerResponse>(
      {url: `/Producers`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: producerUpdateModel
    },
      );
    }
  


    export type ProducersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof producersUpdate>>>
    export type ProducersUpdateMutationBody = BodyType<ProducerUpdateModel>
    export type ProducersUpdateMutationError = ErrorType<unknown>

    export const useProducersUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof producersUpdate>>, TError,{data: BodyType<ProducerUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof producersUpdate>>, {data: BodyType<ProducerUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  producersUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof producersUpdate>>, TError, {data: BodyType<ProducerUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const producersCreate = (
    producerCreateModel: BodyType<ProducerCreateModel>,
 ) => {
      return customInstance<ProducerResponse>(
      {url: `/Producers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: producerCreateModel
    },
      );
    }
  


    export type ProducersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof producersCreate>>>
    export type ProducersCreateMutationBody = BodyType<ProducerCreateModel>
    export type ProducersCreateMutationError = ErrorType<unknown>

    export const useProducersCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof producersCreate>>, TError,{data: BodyType<ProducerCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof producersCreate>>, {data: BodyType<ProducerCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  producersCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof producersCreate>>, TError, {data: BodyType<ProducerCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const producersGetDetails = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ProducerResponse>(
      {url: `/Producers/${id}`, method: 'get', signal
    },
      );
    }
  

export const getProducersGetDetailsQueryKey = (id: string,) => [`/Producers/${id}`];

    
export type ProducersGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof producersGetDetails>>>
export type ProducersGetDetailsQueryError = ErrorType<unknown>

export const useProducersGetDetails = <TData = Awaited<ReturnType<typeof producersGetDetails>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof producersGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProducersGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof producersGetDetails>>> = ({ signal }) => producersGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof producersGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const producersDelete = (
    id: string,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/Producers/${id}`, method: 'delete'
    },
      );
    }
  


    export type ProducersDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof producersDelete>>>
    
    export type ProducersDeleteMutationError = ErrorType<unknown>

    export const useProducersDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof producersDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof producersDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  producersDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof producersDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const recapsWiresChecksIndex = (
    params?: RecapsWiresChecksIndexParams,
 signal?: AbortSignal
) => {
      return customInstance<RecapsWiresAndChecksResponsePaginatedResponse>(
      {url: `/RecapsWiresChecks`, method: 'get',
        params, signal
    },
      );
    }
  

export const getRecapsWiresChecksIndexQueryKey = (params?: RecapsWiresChecksIndexParams,) => [`/RecapsWiresChecks`, ...(params ? [params]: [])];

    
export type RecapsWiresChecksIndexQueryResult = NonNullable<Awaited<ReturnType<typeof recapsWiresChecksIndex>>>
export type RecapsWiresChecksIndexQueryError = ErrorType<unknown>

export const useRecapsWiresChecksIndex = <TData = Awaited<ReturnType<typeof recapsWiresChecksIndex>>, TError = ErrorType<unknown>>(
 params?: RecapsWiresChecksIndexParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recapsWiresChecksIndex>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecapsWiresChecksIndexQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recapsWiresChecksIndex>>> = ({ signal }) => recapsWiresChecksIndex(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof recapsWiresChecksIndex>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const recapsWiresChecksCreate = (
    recapsWiresAndChecksCreateModel: BodyType<RecapsWiresAndChecksCreateModel>,
 ) => {
      return customInstance<RecapsWiresAndChecksResponse>(
      {url: `/RecapsWiresChecks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: recapsWiresAndChecksCreateModel
    },
      );
    }
  


    export type RecapsWiresChecksCreateMutationResult = NonNullable<Awaited<ReturnType<typeof recapsWiresChecksCreate>>>
    export type RecapsWiresChecksCreateMutationBody = BodyType<RecapsWiresAndChecksCreateModel>
    export type RecapsWiresChecksCreateMutationError = ErrorType<unknown>

    export const useRecapsWiresChecksCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recapsWiresChecksCreate>>, TError,{data: BodyType<RecapsWiresAndChecksCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recapsWiresChecksCreate>>, {data: BodyType<RecapsWiresAndChecksCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  recapsWiresChecksCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof recapsWiresChecksCreate>>, TError, {data: BodyType<RecapsWiresAndChecksCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const recapsWiresChecksGetWiresChecksTypeFilters = (
    
 signal?: AbortSignal
) => {
      return customInstance<WiresChecksTypeResponse[]>(
      {url: `/RecapsWiresChecks/filters`, method: 'get', signal
    },
      );
    }
  

export const getRecapsWiresChecksGetWiresChecksTypeFiltersQueryKey = () => [`/RecapsWiresChecks/filters`];

    
export type RecapsWiresChecksGetWiresChecksTypeFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof recapsWiresChecksGetWiresChecksTypeFilters>>>
export type RecapsWiresChecksGetWiresChecksTypeFiltersQueryError = ErrorType<unknown>

export const useRecapsWiresChecksGetWiresChecksTypeFilters = <TData = Awaited<ReturnType<typeof recapsWiresChecksGetWiresChecksTypeFilters>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recapsWiresChecksGetWiresChecksTypeFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecapsWiresChecksGetWiresChecksTypeFiltersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recapsWiresChecksGetWiresChecksTypeFilters>>> = ({ signal }) => recapsWiresChecksGetWiresChecksTypeFilters(signal);

  const query = useQuery<Awaited<ReturnType<typeof recapsWiresChecksGetWiresChecksTypeFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const recapsWiresChecksUpdate = (
    id: number,
    recapsWiresAndChecksUpdateModel: BodyType<RecapsWiresAndChecksUpdateModel>,
 ) => {
      return customInstance<RecapsWiresAndChecksResponse>(
      {url: `/RecapsWiresChecks/${id}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: recapsWiresAndChecksUpdateModel
    },
      );
    }
  


    export type RecapsWiresChecksUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof recapsWiresChecksUpdate>>>
    export type RecapsWiresChecksUpdateMutationBody = BodyType<RecapsWiresAndChecksUpdateModel>
    export type RecapsWiresChecksUpdateMutationError = ErrorType<unknown>

    export const useRecapsWiresChecksUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof recapsWiresChecksUpdate>>, TError,{id: number;data: BodyType<RecapsWiresAndChecksUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof recapsWiresChecksUpdate>>, {id: number;data: BodyType<RecapsWiresAndChecksUpdateModel>}> = (props) => {
          const {id,data} = props ?? {};

          return  recapsWiresChecksUpdate(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof recapsWiresChecksUpdate>>, TError, {id: number;data: BodyType<RecapsWiresAndChecksUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const recapsWiresChecksGetDetails = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<RecapsWiresAndChecksResponse>(
      {url: `/RecapsWiresChecks/${id}`, method: 'get', signal
    },
      );
    }
  

export const getRecapsWiresChecksGetDetailsQueryKey = (id: number,) => [`/RecapsWiresChecks/${id}`];

    
export type RecapsWiresChecksGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof recapsWiresChecksGetDetails>>>
export type RecapsWiresChecksGetDetailsQueryError = ErrorType<unknown>

export const useRecapsWiresChecksGetDetails = <TData = Awaited<ReturnType<typeof recapsWiresChecksGetDetails>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof recapsWiresChecksGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecapsWiresChecksGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recapsWiresChecksGetDetails>>> = ({ signal }) => recapsWiresChecksGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof recapsWiresChecksGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const reportsCoverageByCompanyReport = (
    
 signal?: AbortSignal
) => {
      return customInstance<Base64FileResult>(
      {url: `/Reports/coveragebycompany`, method: 'get', signal
    },
      );
    }
  

export const getReportsCoverageByCompanyReportQueryKey = () => [`/Reports/coveragebycompany`];

    
export type ReportsCoverageByCompanyReportQueryResult = NonNullable<Awaited<ReturnType<typeof reportsCoverageByCompanyReport>>>
export type ReportsCoverageByCompanyReportQueryError = ErrorType<unknown>

export const useReportsCoverageByCompanyReport = <TData = Awaited<ReturnType<typeof reportsCoverageByCompanyReport>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof reportsCoverageByCompanyReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReportsCoverageByCompanyReportQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsCoverageByCompanyReport>>> = ({ signal }) => reportsCoverageByCompanyReport(signal);

  const query = useQuery<Awaited<ReturnType<typeof reportsCoverageByCompanyReport>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const reportsCoverageByIndustryReport = (
    
 signal?: AbortSignal
) => {
      return customInstance<Base64FileResult>(
      {url: `/Reports/coveragebyindustry`, method: 'get', signal
    },
      );
    }
  

export const getReportsCoverageByIndustryReportQueryKey = () => [`/Reports/coveragebyindustry`];

    
export type ReportsCoverageByIndustryReportQueryResult = NonNullable<Awaited<ReturnType<typeof reportsCoverageByIndustryReport>>>
export type ReportsCoverageByIndustryReportQueryError = ErrorType<unknown>

export const useReportsCoverageByIndustryReport = <TData = Awaited<ReturnType<typeof reportsCoverageByIndustryReport>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof reportsCoverageByIndustryReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReportsCoverageByIndustryReportQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsCoverageByIndustryReport>>> = ({ signal }) => reportsCoverageByIndustryReport(signal);

  const query = useQuery<Awaited<ReturnType<typeof reportsCoverageByIndustryReport>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const researchExclusionGet = (
    params?: ResearchExclusionGetParams,
 signal?: AbortSignal
) => {
      return customInstance<ResearchExclusionResponsePaginatedResponse>(
      {url: `/ResearchExclusion`, method: 'get',
        params, signal
    },
      );
    }
  

export const getResearchExclusionGetQueryKey = (params?: ResearchExclusionGetParams,) => [`/ResearchExclusion`, ...(params ? [params]: [])];

    
export type ResearchExclusionGetQueryResult = NonNullable<Awaited<ReturnType<typeof researchExclusionGet>>>
export type ResearchExclusionGetQueryError = ErrorType<unknown>

export const useResearchExclusionGet = <TData = Awaited<ReturnType<typeof researchExclusionGet>>, TError = ErrorType<unknown>>(
 params?: ResearchExclusionGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof researchExclusionGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getResearchExclusionGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof researchExclusionGet>>> = ({ signal }) => researchExclusionGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof researchExclusionGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const researchExclusionUpdate = (
    researchExclusionUpdateModel: BodyType<ResearchExclusionUpdateModel>,
 ) => {
      return customInstance<ResearchExclusionResponse>(
      {url: `/ResearchExclusion`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: researchExclusionUpdateModel
    },
      );
    }
  


    export type ResearchExclusionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof researchExclusionUpdate>>>
    export type ResearchExclusionUpdateMutationBody = BodyType<ResearchExclusionUpdateModel>
    export type ResearchExclusionUpdateMutationError = ErrorType<unknown>

    export const useResearchExclusionUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof researchExclusionUpdate>>, TError,{data: BodyType<ResearchExclusionUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof researchExclusionUpdate>>, {data: BodyType<ResearchExclusionUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  researchExclusionUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof researchExclusionUpdate>>, TError, {data: BodyType<ResearchExclusionUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const researchExclusionCreate = (
    researchExclusionCreateModel: BodyType<ResearchExclusionCreateModel>,
 ) => {
      return customInstance<ResearchExclusionResponse>(
      {url: `/ResearchExclusion`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: researchExclusionCreateModel
    },
      );
    }
  


    export type ResearchExclusionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof researchExclusionCreate>>>
    export type ResearchExclusionCreateMutationBody = BodyType<ResearchExclusionCreateModel>
    export type ResearchExclusionCreateMutationError = ErrorType<unknown>

    export const useResearchExclusionCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof researchExclusionCreate>>, TError,{data: BodyType<ResearchExclusionCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof researchExclusionCreate>>, {data: BodyType<ResearchExclusionCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  researchExclusionCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof researchExclusionCreate>>, TError, {data: BodyType<ResearchExclusionCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const researchExclusionDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/ResearchExclusion/${id}`, method: 'delete'
    },
      );
    }
  


    export type ResearchExclusionDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof researchExclusionDelete>>>
    
    export type ResearchExclusionDeleteMutationError = ErrorType<unknown>

    export const useResearchExclusionDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof researchExclusionDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof researchExclusionDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  researchExclusionDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof researchExclusionDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const researchInclusionGet = (
    params?: ResearchInclusionGetParams,
 signal?: AbortSignal
) => {
      return customInstance<ResearchInclusionResponsePaginatedResponse>(
      {url: `/ResearchInclusion`, method: 'get',
        params, signal
    },
      );
    }
  

export const getResearchInclusionGetQueryKey = (params?: ResearchInclusionGetParams,) => [`/ResearchInclusion`, ...(params ? [params]: [])];

    
export type ResearchInclusionGetQueryResult = NonNullable<Awaited<ReturnType<typeof researchInclusionGet>>>
export type ResearchInclusionGetQueryError = ErrorType<unknown>

export const useResearchInclusionGet = <TData = Awaited<ReturnType<typeof researchInclusionGet>>, TError = ErrorType<unknown>>(
 params?: ResearchInclusionGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof researchInclusionGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getResearchInclusionGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof researchInclusionGet>>> = ({ signal }) => researchInclusionGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof researchInclusionGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const researchInclusionUpdate = (
    researchInclusionUpdateModel: BodyType<ResearchInclusionUpdateModel>,
 ) => {
      return customInstance<ResearchInclusionResponse>(
      {url: `/ResearchInclusion`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: researchInclusionUpdateModel
    },
      );
    }
  


    export type ResearchInclusionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof researchInclusionUpdate>>>
    export type ResearchInclusionUpdateMutationBody = BodyType<ResearchInclusionUpdateModel>
    export type ResearchInclusionUpdateMutationError = ErrorType<unknown>

    export const useResearchInclusionUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof researchInclusionUpdate>>, TError,{data: BodyType<ResearchInclusionUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof researchInclusionUpdate>>, {data: BodyType<ResearchInclusionUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  researchInclusionUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof researchInclusionUpdate>>, TError, {data: BodyType<ResearchInclusionUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const researchInclusionCreate = (
    researchInclusionCreateModel: BodyType<ResearchInclusionCreateModel>,
 ) => {
      return customInstance<ResearchInclusionResponse>(
      {url: `/ResearchInclusion`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: researchInclusionCreateModel
    },
      );
    }
  


    export type ResearchInclusionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof researchInclusionCreate>>>
    export type ResearchInclusionCreateMutationBody = BodyType<ResearchInclusionCreateModel>
    export type ResearchInclusionCreateMutationError = ErrorType<unknown>

    export const useResearchInclusionCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof researchInclusionCreate>>, TError,{data: BodyType<ResearchInclusionCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof researchInclusionCreate>>, {data: BodyType<ResearchInclusionCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  researchInclusionCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof researchInclusionCreate>>, TError, {data: BodyType<ResearchInclusionCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const researchInclusionDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/ResearchInclusion/${id}`, method: 'delete'
    },
      );
    }
  


    export type ResearchInclusionDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof researchInclusionDelete>>>
    
    export type ResearchInclusionDeleteMutationError = ErrorType<unknown>

    export const useResearchInclusionDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof researchInclusionDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof researchInclusionDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  researchInclusionDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof researchInclusionDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const restrictedSchwabDataGetRestrictedSchwabDataAggregate = (
    params?: RestrictedSchwabDataGetRestrictedSchwabDataAggregateParams,
 signal?: AbortSignal
) => {
      return customInstance<RestrictedSchwabDataResponsePaginatedResponse>(
      {url: `/RestrictedSchwabData`, method: 'get',
        params, signal
    },
      );
    }
  

export const getRestrictedSchwabDataGetRestrictedSchwabDataAggregateQueryKey = (params?: RestrictedSchwabDataGetRestrictedSchwabDataAggregateParams,) => [`/RestrictedSchwabData`, ...(params ? [params]: [])];

    
export type RestrictedSchwabDataGetRestrictedSchwabDataAggregateQueryResult = NonNullable<Awaited<ReturnType<typeof restrictedSchwabDataGetRestrictedSchwabDataAggregate>>>
export type RestrictedSchwabDataGetRestrictedSchwabDataAggregateQueryError = ErrorType<unknown>

export const useRestrictedSchwabDataGetRestrictedSchwabDataAggregate = <TData = Awaited<ReturnType<typeof restrictedSchwabDataGetRestrictedSchwabDataAggregate>>, TError = ErrorType<unknown>>(
 params?: RestrictedSchwabDataGetRestrictedSchwabDataAggregateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof restrictedSchwabDataGetRestrictedSchwabDataAggregate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRestrictedSchwabDataGetRestrictedSchwabDataAggregateQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof restrictedSchwabDataGetRestrictedSchwabDataAggregate>>> = ({ signal }) => restrictedSchwabDataGetRestrictedSchwabDataAggregate(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof restrictedSchwabDataGetRestrictedSchwabDataAggregate>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const restrictedSchwabDataDownloadRestrictedSchwabData = (
    params?: RestrictedSchwabDataDownloadRestrictedSchwabDataParams,
 signal?: AbortSignal
) => {
      return customInstance<DownloadResponse>(
      {url: `/RestrictedSchwabData/download`, method: 'get',
        params, signal
    },
      );
    }
  

export const getRestrictedSchwabDataDownloadRestrictedSchwabDataQueryKey = (params?: RestrictedSchwabDataDownloadRestrictedSchwabDataParams,) => [`/RestrictedSchwabData/download`, ...(params ? [params]: [])];

    
export type RestrictedSchwabDataDownloadRestrictedSchwabDataQueryResult = NonNullable<Awaited<ReturnType<typeof restrictedSchwabDataDownloadRestrictedSchwabData>>>
export type RestrictedSchwabDataDownloadRestrictedSchwabDataQueryError = ErrorType<unknown>

export const useRestrictedSchwabDataDownloadRestrictedSchwabData = <TData = Awaited<ReturnType<typeof restrictedSchwabDataDownloadRestrictedSchwabData>>, TError = ErrorType<unknown>>(
 params?: RestrictedSchwabDataDownloadRestrictedSchwabDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof restrictedSchwabDataDownloadRestrictedSchwabData>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRestrictedSchwabDataDownloadRestrictedSchwabDataQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof restrictedSchwabDataDownloadRestrictedSchwabData>>> = ({ signal }) => restrictedSchwabDataDownloadRestrictedSchwabData(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof restrictedSchwabDataDownloadRestrictedSchwabData>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const restrictedSchwabDataEmailRestrictedSchwabData = (
    params?: RestrictedSchwabDataEmailRestrictedSchwabDataParams,
 signal?: AbortSignal
) => {
      return customInstance<EmailDownloadResponse>(
      {url: `/RestrictedSchwabData/email`, method: 'get',
        params, signal
    },
      );
    }
  

export const getRestrictedSchwabDataEmailRestrictedSchwabDataQueryKey = (params?: RestrictedSchwabDataEmailRestrictedSchwabDataParams,) => [`/RestrictedSchwabData/email`, ...(params ? [params]: [])];

    
export type RestrictedSchwabDataEmailRestrictedSchwabDataQueryResult = NonNullable<Awaited<ReturnType<typeof restrictedSchwabDataEmailRestrictedSchwabData>>>
export type RestrictedSchwabDataEmailRestrictedSchwabDataQueryError = ErrorType<unknown>

export const useRestrictedSchwabDataEmailRestrictedSchwabData = <TData = Awaited<ReturnType<typeof restrictedSchwabDataEmailRestrictedSchwabData>>, TError = ErrorType<unknown>>(
 params?: RestrictedSchwabDataEmailRestrictedSchwabDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof restrictedSchwabDataEmailRestrictedSchwabData>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRestrictedSchwabDataEmailRestrictedSchwabDataQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof restrictedSchwabDataEmailRestrictedSchwabData>>> = ({ signal }) => restrictedSchwabDataEmailRestrictedSchwabData(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof restrictedSchwabDataEmailRestrictedSchwabData>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const restrictionTypeGetRestrictionTypes = (
    
 signal?: AbortSignal
) => {
      return customInstance<RestrictionTypeResponse[]>(
      {url: `/RestrictionType`, method: 'get', signal
    },
      );
    }
  

export const getRestrictionTypeGetRestrictionTypesQueryKey = () => [`/RestrictionType`];

    
export type RestrictionTypeGetRestrictionTypesQueryResult = NonNullable<Awaited<ReturnType<typeof restrictionTypeGetRestrictionTypes>>>
export type RestrictionTypeGetRestrictionTypesQueryError = ErrorType<unknown>

export const useRestrictionTypeGetRestrictionTypes = <TData = Awaited<ReturnType<typeof restrictionTypeGetRestrictionTypes>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof restrictionTypeGetRestrictionTypes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRestrictionTypeGetRestrictionTypesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof restrictionTypeGetRestrictionTypes>>> = ({ signal }) => restrictionTypeGetRestrictionTypes(signal);

  const query = useQuery<Awaited<ReturnType<typeof restrictionTypeGetRestrictionTypes>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schwabTransactionsUpload = (
    schwabTransactionsUploadBody: BodyType<SchwabTransactionsUploadBody>,
 ) => {const formData = new FormData();
if(schwabTransactionsUploadBody.file !== undefined) {
 formData.append('file', schwabTransactionsUploadBody.file)
 }

      return customInstance<void>(
      {url: `/SchwabTransactions/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


    export type SchwabTransactionsUploadMutationResult = NonNullable<Awaited<ReturnType<typeof schwabTransactionsUpload>>>
    export type SchwabTransactionsUploadMutationBody = BodyType<SchwabTransactionsUploadBody>
    export type SchwabTransactionsUploadMutationError = ErrorType<unknown>

    export const useSchwabTransactionsUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schwabTransactionsUpload>>, TError,{data: BodyType<SchwabTransactionsUploadBody>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schwabTransactionsUpload>>, {data: BodyType<SchwabTransactionsUploadBody>}> = (props) => {
          const {data} = props ?? {};

          return  schwabTransactionsUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof schwabTransactionsUpload>>, TError, {data: BodyType<SchwabTransactionsUploadBody>}, TContext>(mutationFn, mutationOptions)
    }
    
export const subAccountGet = (
    params?: SubAccountGetParams,
 signal?: AbortSignal
) => {
      return customInstance<SubAccountResponsePaginatedResponse>(
      {url: `/SubAccount`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSubAccountGetQueryKey = (params?: SubAccountGetParams,) => [`/SubAccount`, ...(params ? [params]: [])];

    
export type SubAccountGetQueryResult = NonNullable<Awaited<ReturnType<typeof subAccountGet>>>
export type SubAccountGetQueryError = ErrorType<unknown>

export const useSubAccountGet = <TData = Awaited<ReturnType<typeof subAccountGet>>, TError = ErrorType<unknown>>(
 params?: SubAccountGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof subAccountGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubAccountGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subAccountGet>>> = ({ signal }) => subAccountGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof subAccountGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const subAccountUpdate = (
    subAccountUpdateModel: BodyType<SubAccountUpdateModel>,
 ) => {
      return customInstance<SubAccountResponse>(
      {url: `/SubAccount`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: subAccountUpdateModel
    },
      );
    }
  


    export type SubAccountUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof subAccountUpdate>>>
    export type SubAccountUpdateMutationBody = BodyType<SubAccountUpdateModel>
    export type SubAccountUpdateMutationError = ErrorType<unknown>

    export const useSubAccountUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof subAccountUpdate>>, TError,{data: BodyType<SubAccountUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof subAccountUpdate>>, {data: BodyType<SubAccountUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  subAccountUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof subAccountUpdate>>, TError, {data: BodyType<SubAccountUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const subAccountCreate = (
    subAccountCreateModel: BodyType<SubAccountCreateModel>,
 ) => {
      return customInstance<SubAccountResponse>(
      {url: `/SubAccount`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: subAccountCreateModel
    },
      );
    }
  


    export type SubAccountCreateMutationResult = NonNullable<Awaited<ReturnType<typeof subAccountCreate>>>
    export type SubAccountCreateMutationBody = BodyType<SubAccountCreateModel>
    export type SubAccountCreateMutationError = ErrorType<unknown>

    export const useSubAccountCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof subAccountCreate>>, TError,{data: BodyType<SubAccountCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof subAccountCreate>>, {data: BodyType<SubAccountCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  subAccountCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof subAccountCreate>>, TError, {data: BodyType<SubAccountCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const subAccountGetDetails = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<SubAccountResponse>(
      {url: `/SubAccount/${id}`, method: 'get', signal
    },
      );
    }
  

export const getSubAccountGetDetailsQueryKey = (id: number,) => [`/SubAccount/${id}`];

    
export type SubAccountGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof subAccountGetDetails>>>
export type SubAccountGetDetailsQueryError = ErrorType<unknown>

export const useSubAccountGetDetails = <TData = Awaited<ReturnType<typeof subAccountGetDetails>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof subAccountGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubAccountGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subAccountGetDetails>>> = ({ signal }) => subAccountGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof subAccountGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const subAccountDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/SubAccount/${id}`, method: 'delete'
    },
      );
    }
  


    export type SubAccountDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof subAccountDelete>>>
    
    export type SubAccountDeleteMutationError = ErrorType<unknown>

    export const useSubAccountDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof subAccountDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof subAccountDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  subAccountDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof subAccountDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const subAccountGetSubAccountFilters = (
    
 signal?: AbortSignal
) => {
      return customInstance<SubAccountFilterResponse[]>(
      {url: `/SubAccount/filters`, method: 'get', signal
    },
      );
    }
  

export const getSubAccountGetSubAccountFiltersQueryKey = () => [`/SubAccount/filters`];

    
export type SubAccountGetSubAccountFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof subAccountGetSubAccountFilters>>>
export type SubAccountGetSubAccountFiltersQueryError = ErrorType<unknown>

export const useSubAccountGetSubAccountFilters = <TData = Awaited<ReturnType<typeof subAccountGetSubAccountFilters>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof subAccountGetSubAccountFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSubAccountGetSubAccountFiltersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof subAccountGetSubAccountFilters>>> = ({ signal }) => subAccountGetSubAccountFilters(signal);

  const query = useQuery<Awaited<ReturnType<typeof subAccountGetSubAccountFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolGet = (
    params?: SymbolGetParams,
 signal?: AbortSignal
) => {
      return customInstance<SymbolResponsePaginatedResponse>(
      {url: `/data/Symbol`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSymbolGetQueryKey = (params?: SymbolGetParams,) => [`/data/Symbol`, ...(params ? [params]: [])];

    
export type SymbolGetQueryResult = NonNullable<Awaited<ReturnType<typeof symbolGet>>>
export type SymbolGetQueryError = ErrorType<unknown>

export const useSymbolGet = <TData = Awaited<ReturnType<typeof symbolGet>>, TError = ErrorType<unknown>>(
 params?: SymbolGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolGet>>> = ({ signal }) => symbolGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolUpdate = (
    symbolUpdateModel: BodyType<SymbolUpdateModel>,
 ) => {
      return customInstance<SymbolResponse>(
      {url: `/data/Symbol`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: symbolUpdateModel
    },
      );
    }
  


    export type SymbolUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof symbolUpdate>>>
    export type SymbolUpdateMutationBody = BodyType<SymbolUpdateModel>
    export type SymbolUpdateMutationError = ErrorType<unknown>

    export const useSymbolUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof symbolUpdate>>, TError,{data: BodyType<SymbolUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof symbolUpdate>>, {data: BodyType<SymbolUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  symbolUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof symbolUpdate>>, TError, {data: BodyType<SymbolUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const symbolCreate = (
    symbolCreateModel: BodyType<SymbolCreateModel>,
 ) => {
      return customInstance<SymbolResponse>(
      {url: `/data/Symbol`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: symbolCreateModel
    },
      );
    }
  


    export type SymbolCreateMutationResult = NonNullable<Awaited<ReturnType<typeof symbolCreate>>>
    export type SymbolCreateMutationBody = BodyType<SymbolCreateModel>
    export type SymbolCreateMutationError = ErrorType<unknown>

    export const useSymbolCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof symbolCreate>>, TError,{data: BodyType<SymbolCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof symbolCreate>>, {data: BodyType<SymbolCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  symbolCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof symbolCreate>>, TError, {data: BodyType<SymbolCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const symbolGetSymbolFilters = (
    
 signal?: AbortSignal
) => {
      return customInstance<SymbolFilterResponse[]>(
      {url: `/data/Symbol/filters`, method: 'get', signal
    },
      );
    }
  

export const getSymbolGetSymbolFiltersQueryKey = () => [`/data/Symbol/filters`];

    
export type SymbolGetSymbolFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof symbolGetSymbolFilters>>>
export type SymbolGetSymbolFiltersQueryError = ErrorType<unknown>

export const useSymbolGetSymbolFilters = <TData = Awaited<ReturnType<typeof symbolGetSymbolFilters>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolGetSymbolFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolGetSymbolFiltersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolGetSymbolFilters>>> = ({ signal }) => symbolGetSymbolFilters(signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolGetSymbolFilters>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/data/Symbol/${id}`, method: 'delete'
    },
      );
    }
  


    export type SymbolDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof symbolDelete>>>
    
    export type SymbolDeleteMutationError = ErrorType<unknown>

    export const useSymbolDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof symbolDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof symbolDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  symbolDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof symbolDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const symbolGroupsGroups = (
    
 signal?: AbortSignal
) => {
      return customInstance<SymbolGroupResponse[]>(
      {url: `/SymbolGroups`, method: 'get', signal
    },
      );
    }
  

export const getSymbolGroupsGroupsQueryKey = () => [`/SymbolGroups`];

    
export type SymbolGroupsGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof symbolGroupsGroups>>>
export type SymbolGroupsGroupsQueryError = ErrorType<unknown>

export const useSymbolGroupsGroups = <TData = Awaited<ReturnType<typeof symbolGroupsGroups>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolGroupsGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolGroupsGroupsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolGroupsGroups>>> = ({ signal }) => symbolGroupsGroups(signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolGroupsGroups>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolGroupsSubGroups = (
    
 signal?: AbortSignal
) => {
      return customInstance<SymbolSubGroupResponse[]>(
      {url: `/SymbolGroups/subgroups`, method: 'get', signal
    },
      );
    }
  

export const getSymbolGroupsSubGroupsQueryKey = () => [`/SymbolGroups/subgroups`];

    
export type SymbolGroupsSubGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof symbolGroupsSubGroups>>>
export type SymbolGroupsSubGroupsQueryError = ErrorType<unknown>

export const useSymbolGroupsSubGroups = <TData = Awaited<ReturnType<typeof symbolGroupsSubGroups>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolGroupsSubGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolGroupsSubGroupsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolGroupsSubGroups>>> = ({ signal }) => symbolGroupsSubGroups(signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolGroupsSubGroups>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolListsGetAll = (
    
 signal?: AbortSignal
) => {
      return customInstance<SymbolListResponse[]>(
      {url: `/SymbolLists`, method: 'get', signal
    },
      );
    }
  

export const getSymbolListsGetAllQueryKey = () => [`/SymbolLists`];

    
export type SymbolListsGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof symbolListsGetAll>>>
export type SymbolListsGetAllQueryError = ErrorType<unknown>

export const useSymbolListsGetAll = <TData = Awaited<ReturnType<typeof symbolListsGetAll>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolListsGetAll>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolListsGetAllQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolListsGetAll>>> = ({ signal }) => symbolListsGetAll(signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolListsGetAll>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolRestrictionGet = (
    params?: SymbolRestrictionGetParams,
 signal?: AbortSignal
) => {
      return customInstance<SymbolRestrictionResponsePaginatedResponse>(
      {url: `/SymbolRestriction`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSymbolRestrictionGetQueryKey = (params?: SymbolRestrictionGetParams,) => [`/SymbolRestriction`, ...(params ? [params]: [])];

    
export type SymbolRestrictionGetQueryResult = NonNullable<Awaited<ReturnType<typeof symbolRestrictionGet>>>
export type SymbolRestrictionGetQueryError = ErrorType<unknown>

export const useSymbolRestrictionGet = <TData = Awaited<ReturnType<typeof symbolRestrictionGet>>, TError = ErrorType<unknown>>(
 params?: SymbolRestrictionGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolRestrictionGet>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolRestrictionGetQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolRestrictionGet>>> = ({ signal }) => symbolRestrictionGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolRestrictionGet>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolRestrictionUpdate = (
    symbolRestrictionUpdateModel: BodyType<SymbolRestrictionUpdateModel>,
 ) => {
      return customInstance<SymbolRestrictionResponse>(
      {url: `/SymbolRestriction`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: symbolRestrictionUpdateModel
    },
      );
    }
  


    export type SymbolRestrictionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof symbolRestrictionUpdate>>>
    export type SymbolRestrictionUpdateMutationBody = BodyType<SymbolRestrictionUpdateModel>
    export type SymbolRestrictionUpdateMutationError = ErrorType<unknown>

    export const useSymbolRestrictionUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof symbolRestrictionUpdate>>, TError,{data: BodyType<SymbolRestrictionUpdateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof symbolRestrictionUpdate>>, {data: BodyType<SymbolRestrictionUpdateModel>}> = (props) => {
          const {data} = props ?? {};

          return  symbolRestrictionUpdate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof symbolRestrictionUpdate>>, TError, {data: BodyType<SymbolRestrictionUpdateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const symbolRestrictionCreate = (
    symbolRestrictionCreateModel: BodyType<SymbolRestrictionCreateModel>,
 ) => {
      return customInstance<SymbolRestrictionResponse>(
      {url: `/SymbolRestriction`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: symbolRestrictionCreateModel
    },
      );
    }
  


    export type SymbolRestrictionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof symbolRestrictionCreate>>>
    export type SymbolRestrictionCreateMutationBody = BodyType<SymbolRestrictionCreateModel>
    export type SymbolRestrictionCreateMutationError = ErrorType<unknown>

    export const useSymbolRestrictionCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof symbolRestrictionCreate>>, TError,{data: BodyType<SymbolRestrictionCreateModel>}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof symbolRestrictionCreate>>, {data: BodyType<SymbolRestrictionCreateModel>}> = (props) => {
          const {data} = props ?? {};

          return  symbolRestrictionCreate(data,)
        }

      return useMutation<Awaited<ReturnType<typeof symbolRestrictionCreate>>, TError, {data: BodyType<SymbolRestrictionCreateModel>}, TContext>(mutationFn, mutationOptions)
    }
    
export const symbolRestrictionGetDetails = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<SymbolRestrictionResponse>(
      {url: `/SymbolRestriction/${id}`, method: 'get', signal
    },
      );
    }
  

export const getSymbolRestrictionGetDetailsQueryKey = (id: number,) => [`/SymbolRestriction/${id}`];

    
export type SymbolRestrictionGetDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof symbolRestrictionGetDetails>>>
export type SymbolRestrictionGetDetailsQueryError = ErrorType<unknown>

export const useSymbolRestrictionGetDetails = <TData = Awaited<ReturnType<typeof symbolRestrictionGetDetails>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof symbolRestrictionGetDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSymbolRestrictionGetDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof symbolRestrictionGetDetails>>> = ({ signal }) => symbolRestrictionGetDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof symbolRestrictionGetDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const symbolRestrictionDelete = (
    id: number,
 ) => {
      return customInstance<DeletedResponse>(
      {url: `/SymbolRestriction/${id}`, method: 'delete'
    },
      );
    }
  


    export type SymbolRestrictionDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof symbolRestrictionDelete>>>
    
    export type SymbolRestrictionDeleteMutationError = ErrorType<unknown>

    export const useSymbolRestrictionDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof symbolRestrictionDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof symbolRestrictionDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  symbolRestrictionDelete(id,)
        }

      return useMutation<Awaited<ReturnType<typeof symbolRestrictionDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions)
    }
    
export const tradeAheadGetTradeAheadReports = (
    params?: TradeAheadGetTradeAheadReportsParams,
 signal?: AbortSignal
) => {
      return customInstance<TradeAheadResponsePaginatedResponse>(
      {url: `/TradeAhead`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTradeAheadGetTradeAheadReportsQueryKey = (params?: TradeAheadGetTradeAheadReportsParams,) => [`/TradeAhead`, ...(params ? [params]: [])];

    
export type TradeAheadGetTradeAheadReportsQueryResult = NonNullable<Awaited<ReturnType<typeof tradeAheadGetTradeAheadReports>>>
export type TradeAheadGetTradeAheadReportsQueryError = ErrorType<unknown>

export const useTradeAheadGetTradeAheadReports = <TData = Awaited<ReturnType<typeof tradeAheadGetTradeAheadReports>>, TError = ErrorType<unknown>>(
 params?: TradeAheadGetTradeAheadReportsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof tradeAheadGetTradeAheadReports>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTradeAheadGetTradeAheadReportsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tradeAheadGetTradeAheadReports>>> = ({ signal }) => tradeAheadGetTradeAheadReports(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof tradeAheadGetTradeAheadReports>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const tradeAheadDownloadTradeAhead = (
    params?: TradeAheadDownloadTradeAheadParams,
 signal?: AbortSignal
) => {
      return customInstance<DownloadResponse>(
      {url: `/TradeAhead/download`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTradeAheadDownloadTradeAheadQueryKey = (params?: TradeAheadDownloadTradeAheadParams,) => [`/TradeAhead/download`, ...(params ? [params]: [])];

    
export type TradeAheadDownloadTradeAheadQueryResult = NonNullable<Awaited<ReturnType<typeof tradeAheadDownloadTradeAhead>>>
export type TradeAheadDownloadTradeAheadQueryError = ErrorType<unknown>

export const useTradeAheadDownloadTradeAhead = <TData = Awaited<ReturnType<typeof tradeAheadDownloadTradeAhead>>, TError = ErrorType<unknown>>(
 params?: TradeAheadDownloadTradeAheadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof tradeAheadDownloadTradeAhead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTradeAheadDownloadTradeAheadQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tradeAheadDownloadTradeAhead>>> = ({ signal }) => tradeAheadDownloadTradeAhead(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof tradeAheadDownloadTradeAhead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const tradeAheadGetLatestImportDate = (
    
 signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/TradeAhead/latest-import-date`, method: 'get', signal
    },
      );
    }
  

export const getTradeAheadGetLatestImportDateQueryKey = () => [`/TradeAhead/latest-import-date`];

    
export type TradeAheadGetLatestImportDateQueryResult = NonNullable<Awaited<ReturnType<typeof tradeAheadGetLatestImportDate>>>
export type TradeAheadGetLatestImportDateQueryError = ErrorType<unknown>

export const useTradeAheadGetLatestImportDate = <TData = Awaited<ReturnType<typeof tradeAheadGetLatestImportDate>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof tradeAheadGetLatestImportDate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTradeAheadGetLatestImportDateQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tradeAheadGetLatestImportDate>>> = ({ signal }) => tradeAheadGetLatestImportDate(signal);

  const query = useQuery<Awaited<ReturnType<typeof tradeAheadGetLatestImportDate>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getApiWatchlistTrades = (
    params?: GetApiWatchlistTradesParams,
 signal?: AbortSignal
) => {
      return customInstance<ResponsePaginatedResponse>(
      {url: `/api/WatchlistTrades`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetApiWatchlistTradesQueryKey = (params?: GetApiWatchlistTradesParams,) => [`/api/WatchlistTrades`, ...(params ? [params]: [])];

    
export type GetApiWatchlistTradesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiWatchlistTrades>>>
export type GetApiWatchlistTradesQueryError = ErrorType<unknown>

export const useGetApiWatchlistTrades = <TData = Awaited<ReturnType<typeof getApiWatchlistTrades>>, TError = ErrorType<unknown>>(
 params?: GetApiWatchlistTradesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiWatchlistTrades>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApiWatchlistTradesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiWatchlistTrades>>> = ({ signal }) => getApiWatchlistTrades(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getApiWatchlistTrades>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const wiresChecksIndex = (
    params?: WiresChecksIndexParams,
 signal?: AbortSignal
) => {
      return customInstance<WiresChecksResponsePaginatedResponse>(
      {url: `/WiresChecks`, method: 'get',
        params, signal
    },
      );
    }
  

export const getWiresChecksIndexQueryKey = (params?: WiresChecksIndexParams,) => [`/WiresChecks`, ...(params ? [params]: [])];

    
export type WiresChecksIndexQueryResult = NonNullable<Awaited<ReturnType<typeof wiresChecksIndex>>>
export type WiresChecksIndexQueryError = ErrorType<unknown>

export const useWiresChecksIndex = <TData = Awaited<ReturnType<typeof wiresChecksIndex>>, TError = ErrorType<unknown>>(
 params?: WiresChecksIndexParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof wiresChecksIndex>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getWiresChecksIndexQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof wiresChecksIndex>>> = ({ signal }) => wiresChecksIndex(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof wiresChecksIndex>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


