import { useState } from 'react';

import { Alert, Paper, Snackbar, Stack } from '@mui/material';
import format from 'date-fns/format';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecapsWiresChecksGetDetails, useRecapsWiresChecksUpdate } from '../../services/commissionapi';
import DailyRecapWiresChecksForm, { DailyRecapWiresChecksFormModel } from './DailyRecapWiresChecksForm';

const DailyRecapWiresChecksPageEdit = () => {
    const { id } = useParams<{ id: string }>();

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const navigate = useNavigate();

    const recap = useRecapsWiresChecksGetDetails(Number(id));

    const { mutateAsync: updateRecord } = useRecapsWiresChecksUpdate({
        mutation: {
            onSuccess: (data, variables, context) => {
                setShowSuccess(true);
                // redirect to the detail page of created resource
                navigate(`/daily-recap`);
            },
            onError: () => {
                setShowError(true);
            },
        },
    });

    const OnSubmit = (data: DailyRecapWiresChecksFormModel) => {
        updateRecord({
            id: Number(id),
            data: {
                wiresChecksTypeId: data.wiresChecksType?.id ? Number(data.wiresChecksType.id) : undefined,
                tradeDate: data.tradeDate ? new Date(data.tradeDate).toISOString() : undefined,
                actionId: data.action?.id ? Number(data.action.id) : undefined,
                shares: data.shares ? Number(data.shares) : undefined,
                symbolId: data.symbol?.id ? Number(data.symbol.id) : undefined,
                moneyManagerId: data.moneyManager?.id as string,
                producerId: data.producer?.id as string,
                pricePerShare: data.pricePerShare ? Number(data.pricePerShare) : undefined,
                commPerShare: data.commPerShare ? Number(data.commPerShare) : undefined,
                commission: data.commission ? Number(data.commission) : undefined,
                isAdjustment: data.isAdjustment,
                isExcluded: data.isExcluded,
                adjustmentPayDate: data.adjustmentPayDate ? new Date(data.adjustmentPayDate).toISOString() : undefined,
                wiresChecksAccount: data.wiresChecksAccount,
                subAccountId: data.subAccount?.id ? Number(data.subAccount?.id) : undefined,
                vwap: data.vwap ? Number(data.vwap) : undefined,
                notes: data.notes as string
            },
        });
    };
    if (recap.isLoading || recap.isFetching) return <div>Loading...</div>; // TODO Find a better solution as this makes the screen flicker a bit

    return (
        <Paper>
            <Stack direction="column" padding={2} spacing={1}>
                <h2>Edit Recap</h2>
                <DailyRecapWiresChecksForm
                    isNewForm={true}
                    defaultValues={{
                        wiresChecksTypeId: recap.data?.wiresChecksTypeId,
                        tradeDate: recap.data?.tradeDate ? format(new Date(recap.data.tradeDate), 'yyyy-MM-dd') : new Date().toISOString().substring(0, 10),
                        actionId: recap.data?.actionId,
                        shares: recap.data?.shares,
                        symbolId: recap.data?.symbolId,
                        moneyManagerId: recap.data?.moneyManagerId,
                        producerId: recap.data?.producerId,
                        pricePerShare: recap.data?.pricePerShare,
                        commPerShare: recap.data?.commissonPerShare,
                        commission: recap.data?.totalCommisson || 0,
                        isAdjustment: recap.data?.isAdjustment || false,
                        isExcluded: recap.data?.isAdjustment || false,
                        adjustmentPayDate: recap.data?.adjustmentPayDate ? format(new Date(recap.data.adjustmentPayDate), 'yyyy-MM-dd') : undefined,
                        wiresChecksAccount: recap.data?.accountNumber,
                        vwap: recap.data?.vwap,
                        notes: recap.data?.notes,
                        subAccountId: recap.data?.subAccountId
                    }}
                    onSubmit={OnSubmit}
                />
                <Snackbar
                    open={showSuccess}
                    autoHideDuration={5000}
                    onClose={() => setShowSuccess(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
                        Saved!
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={showError}
                    autoHideDuration={5000}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
                        Failed to Update!
                    </Alert>
                </Snackbar>
            </Stack>
        </Paper>
    );
};

export default DailyRecapWiresChecksPageEdit;
