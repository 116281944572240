import { ConfirmProvider } from 'material-ui-confirm';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Layout from './Layout/Layout';
import CommissionAppRoutes from './Layout/Routes';

const { REACT_APP_REFETCH_ON_WINDOW_FOCUS } = process.env;

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: REACT_APP_REFETCH_ON_WINDOW_FOCUS !== "false",
            }
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ConfirmProvider>
                <Layout>
                    <CommissionAppRoutes />
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </ConfirmProvider>
        </QueryClientProvider>
    );
};

export default App;
